import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  summaryCard: {
    margin: theme.spacing(1),
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  summaryCards: {
    display: "flex",
    flexWrap: "wrap",
  },
}));

export function SummaryCard({ title, value, component }) {
  const classes = useStyles();
  return (
    <div className={classes.summaryCards}>
      <Paper elevation={2} className={classes.summaryCard}>
        <Typography color={"textSecondary"} variant="h6" gutterBottom>
          {title}
        </Typography>
        {component || (
          <Typography color={"primary"} variant="h4">
            {value}
          </Typography>
        )}
      </Paper>
    </div>
  );
}

export default SummaryCard;
