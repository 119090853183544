//MATERIAL-UI
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { red, deepPurple, teal, amber, indigo } from "@material-ui/core/colors";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  RouteComponentProps,
} from "react-router-dom";
import "./App.css";
import ShowAutoclaves from "./components/Autoclaves/ShowAutoclaves/ShowAutoclaves";
import StaffLogin from "./components/StaffLogin/StaffLogin";
import ShowAutoclaveLoads from "./components/AutoclaveLoads/ShowAutoclaveLoads";
import ClientLogin from "./components/ClientLogin/ClientLogin";
import GetArticlesPerLoad from "./components/Articles/ArticlesPerLoad/ArticlesPerLoad";
import RegisterAutoclave from "./components/Register/RegisterAutoclave/RegisterAutoclave";
import RegisterNewAutoclave from "./components/Register/RegisterAutoclave/RegisterNewAutoclave";
import UseArticles from "./components/Articles/UseArticles/UseArticles";
// import NewUseArticle from './components/Articles/UseArticles/new-use-article';
import ControlReport from "./components/ControlReport/controlReport";
import Logger from "js-logger";
import AutoclaveLoadListView from "./containers/autoclave-load-listview";
import Dashboard from "./components/Dashboard/dashboard";
import Authenticate from "./components/Authenticate";
import RegisterEmail from "./components/Register/RegisterEmail/RegisterEmail";
import RegisterServiceReasons from "./components/Register/RegsiterOosReason/RegisterServiceReasons";
import RegisterPouchInstruments from "./components/Register/RegisterPouchItems/RegisterPouchItems";
import GetCompanyInfo from "./components/Company/GetCompanyInfo";
import PasswordResetRequest from "./components/Company/PasswordResetRequest";
import ResetPassword from "./components/Company/ResetPassword";
import RegisterNewReader from "./components/Register/RegisterReader/RegisterNewReader";
import GetUsedArticleDetails from "./components/Articles/AllArticles/UsedArticleLog/get-used-article-details";
import ServiceLog from "./components/ServiceLog/service-log";
import BiCallback from "./components/BIResult/BICallback/BiCallback";
import RegisterArticle from "./components/Register/RegisterArticles/RegisterArticle";
import RegisterArticleType from "./components/Register/RegisterArticles/RegisterArticleType";
import RegisterStaff from "./components/Register/Users/RegisterStaff";
import RegisterBranch from "./components/Register/Location/RegisterBranch";
import RegisterBILot from "./components/Register/RegisterBILot/RegisterBILot";
import RegisterArticleSet from "./components/Register/RegisterArticleSet/RegisterArticleSet";
import ArticleKitList from "./components/Register/RegisterArticleSet/ArticleKitList";
import RegisterArticleKit from "./components/Register/RegisterArticleSet/RegisterArticleKit";
// Importing about the Check Status compoents
import CheckStatus from "./components/CheckStatus";
// Files by Harshvardhan
import Dashboard2 from "./components/Dashboard/dashboard_2";
// eslint-disable-next-line react-hooks/rules-of-hooks
Logger.useDefaults();

const customTheme = createTheme({
  //theme settings
  palette: {
    type: "light",
    primary: indigo,
    secondary: amber,
    error: red,
    warning: deepPurple,
    info: deepPurple,
    success: teal,
  },
  typography: {
    fontFamily: [
      "Montserrat",
      "sans-serif",
      '"Segoe UI"',
      '"Helvetica Neue"',
    ].join(","),
    fontSize: 16,
  },
});

function App({ props }: RouteComponentProps<string>) {
  return (
    <MuiThemeProvider theme={customTheme}>
      {/* <Router basename={"/underdev"}> */}
      <Router>
        {/* Code left to set for mobile responsive */}
        {/* <AppBarAndDrawer /> */}
        {/* <div style={{ marginLeft: "260px" }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" to="/">
              Home
            </Link>
            <Typography color="textPrimary">Dashboard</Typography>
          </Breadcrumbs> */}
        <Switch>
          <Route exact path="/main-login" component={ClientLogin} />
          <Route exact path="/staff-login">
            <StaffLogin />
          </Route>
          <Authenticate exact path="/" component={Dashboard} />
          <Authenticate
            exact
            path="/autoclave-process"
            component={ShowAutoclaveLoads}
          />
          {/* <Route exact path="/autoclave-process"
            component={AutoclaveFilterBar} /> */}
          <Authenticate exact path="/autoclaves" component={ShowAutoclaves} />
          <Authenticate
            exact
            path="/avview"
            component={AutoclaveLoadListView}
          />
          {/* <Route exact path="/item-list" component={ItemList} /> */}
          <Authenticate
            exact
            path="/item-list/:id"
            component={GetArticlesPerLoad}
          />
          <Authenticate
            exact
            path="/autoclaves/register-autoclave"
            component={RegisterAutoclave}
          />
          {/* Check Status data */}
          <Authenticate path="/checkstatus" component={CheckStatus} />
          <Authenticate
            exact
            path="/autoclaves/register-newautoclave"
            component={RegisterNewAutoclave}
          />
          <Authenticate
            exact
            path="/register-article-set"
            component={RegisterArticleSet}
          />
          <Authenticate
            exact
            path="/register-article-set/register-kit"
            component={ArticleKitList}
          />
          <Authenticate
            exact
            path="/register-article-set/register-kit/add"
            component={RegisterArticleKit}
          />

          <Authenticate
            exact
            path="/get-control-report"
            component={ControlReport}
          />
          {/* <Route exact path="/use-articles" component={UseArticles} /> */}
          <Route exact path="/reader" component={UseArticles} />
          <Authenticate exact path="/dashboard" component={Dashboard} />
          <Authenticate exact path="/newDashboard" component={Dashboard2} />
          <Authenticate path="/remail" component={RegisterEmail} />
          <Authenticate
            path="/registerservice"
            component={RegisterServiceReasons}
          />
          <Authenticate
            path="/registerpouchitem"
            component={RegisterPouchInstruments}
          />
          <Authenticate path="/registeremail" component={RegisterEmail} />
          <Authenticate path="/companyinfo" component={GetCompanyInfo} />
          <Authenticate path="/register-reader" component={RegisterNewReader} />
          <Route path="/pass-reset" component={PasswordResetRequest} />
          <Route path="/reset-password" component={ResetPassword} />
          <Authenticate
            path="/used-articles"
            component={GetUsedArticleDetails}
          />
          <Authenticate path="/service-log" component={ServiceLog} />
          <Authenticate path="/failed-bi-list" component={BiCallback} />
          <Authenticate path="/register-article" component={RegisterArticle} />
          <Authenticate
            path="/register-article-type"
            component={RegisterArticleType}
          />
          <Authenticate path="/register-staff" component={RegisterStaff} />
          <Authenticate path="/register-branch" component={RegisterBranch} />
          <Authenticate path="/register-bilot" component={RegisterBILot} />
        </Switch>
        {/* </div> */}
      </Router>
    </MuiThemeProvider>
  );
}

export default App;
