import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      fontSize : 50,
      fontFamily: 'Montserrat',
        '& > *': {
          marginTop: theme.spacing(2),
        },
    },
    displayFlex : {
      display: 'flex',
      flexWrap: 'wrap',
      listStyle: 'none',
      padding: theme.spacing(0.5),
      margin: 0,
      marginBottom: 40
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    paperback: {
      padding: theme.spacing(0.5),
      textAlign: 'center',
    },
    formControl: {
      margin: theme.spacing(1),
      // minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    avatar: {
      backgroundColor: blue[100],
      color: blue[600],
    },
    table: {
      minWidth: 650,
    },
    chip: {
      margin: theme.spacing(0.2),
      fontSize: '12px'
    },
  }),
);