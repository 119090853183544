/* eslint-disable */

import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import { useStyles } from "../Styles/mui-styles";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Collapse from "@material-ui/core/Collapse";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import FilterListIcon from "@material-ui/icons/FilterList";
import axios from "axios";
import SessionData from "../../services/session-data";
import { avResponseType } from "../Autoclaves/ShowAutoclaves/ShowAutoclaves";

const sd = new SessionData();
const API_URL = sd.getBaseURL();

export default function AutoclaveFilterBar(props: any) {
  const [dateChanged, setDateChanged] = useState<boolean>(false);
  const [current_status, set_current_status] = useState<String | unknown>("");
  const [type5_result, set_type5_result] = useState<String | unknown>("");
  const [bi_result, set_bi_result] = useState<String | unknown>("");
  const [cycle_no, set_cycle_no] = useState("_jnvl");
  const [bowiedick_result, set_bowiedick_result] =
    useState<String | unknown>("");
  const [selectedDate, setSelectedDate] =
    React.useState<Date | string | null>(null);
  const [checked, setChecked] = React.useState(false);
  const [height, setHeight] = useState("0px");
  const [whichAv, setWhichAv] = useState<any>("");
  const [autoclaves, setAutoclaves] = useState([]);
  var filterList: avResponseType;

  // const [selectedDate, setSelectedDate] = React.useState<Date | string | null>(
  //   new Date()
  // );
  const classes = useStyles();

  const handleDateChange = (date: Date | null) => {
    if (date !== null && String(date) !== "") {
      setDateChanged(true);
      const dateFormat =
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      setSelectedDate(dateFormat);
    }
  };

  /*React Dialog*/
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // resetValues()
    getAutoclavesList();
    // eslint-disable-next-line no-lone-blocks
    {
      props.open ? setOpen(true) : setOpen(false);
    }
  }, []);

  useEffect(() => {
    props.parentCallback(
      selectedDate,
      current_status,
      type5_result,
      bi_result,
      cycle_no,
      bowiedick_result,
      whichAv
    );
  }, [
    current_status,
    type5_result,
    selectedDate,
    bi_result,
    cycle_no,
    bowiedick_result,
    whichAv,
  ]);

  //show or hide the filter bar
  const handleChange = () => {
    if (checked === false) {
      setChecked(true);
      setHeight("auto");
    } else {
      setHeight("0px");
      setChecked(false);
    }
  };

  // const handleC5Result = (value) => {
  //   String(value) === "Pass" ? set_type5_result(1) : set_type5_result(0);
  // };
  // const handleC2Result = (value) => {
  //   String(value) === "Pass"
  //     ? set_bowiedick_result(1)
  //     : set_bowiedick_result(0);
  // };
  // const handleBIResult = (value) => {
  //   String(value) === "Pass" ? set_bi_result(1) : set_bi_result(0);
  // };

  const clearAllFilters = () => {
    setDateChanged(false);
    set_current_status("");
    set_type5_result("");
    set_bi_result("");
    set_cycle_no("_jnvl");
    set_bowiedick_result("");
    setSelectedDate(null);
    setWhichAv("");
  };

  const getAutoclavesList = () => {
    axios
      .get(API_URL + "sterilizer/web", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("authToken"),
        },
      })
      .then((data) => {
        if (data.status !== 200) throw new Error("Error");
        setAutoclaves(data.data);
      })
      .catch((error) => {});
  };

  const showFilterDialog = () => {
    return (
      <div>
        <Container style={{ maxWidth: "700px" }}>
          <Grid
            container
            spacing={3}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item sm={2} xs={2}>
              <FilterListIcon
                className="filterIcon"
                fontSize="large"
                onClick={handleChange}
              />
              {/* <span>Filter Data</span> */}
              {/* <FormControlLabel
                                control={<Switch checked={checked}  />}
                                label="Filter Data"
                                /> */}
            </Grid>
            <Grid
              item
              style={{ marginTop: "10px", textAlign: "center" }}
              sm={8}
              xs={8}
            >
              <h2 className="jFontBold">Autoclave Loads</h2>
            </Grid>
            <Grid item sm={2} xs={2}>
              <AutorenewIcon
                fontSize="large"
                className="refreshIcon"
                onClick={() => clearAllFilters()}
              />
            </Grid>
          </Grid>
        </Container>
        <Collapse in={checked}>
          <Paper
            className={classes.paper}
            style={{
              height: height,
              marginTop: "20px",
              marginBottom: "20px",
              padding: "20px",
            }}
          >
            <Grid
              container
              justifyContent="space-around"
              spacing={3}
              style={{ marginBottom: "10px" }}
              className="text-left"
            >
              <Grid item xs={6} sm={3} className="text-left">
                {/* <Paper className={classes.paper} style={{height: '100px'}}> style={{maxWidth : '160px'}}    */}
                {/* Date Picker */}

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  {/* <Typography style={{fontSize: '1.2rem', padding: '0px', margin: '0px'}}>Date</Typography> */}
                  <Grid container>
                    <KeyboardDatePicker
                      disableToolbar
                      autoOk={true}
                      variant="inline"
                      label="DATE"
                      format="yyyy-MM-dd"
                      // margin="normal"
                      id="date-picker-inline"
                      // label={<span style={{ fontSize: '2rem'}}>Date</span>}
                      value={selectedDate}
                      onChange={handleDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
                {/* </Paper> */}
              </Grid>
              {/* Select Autoclave */}
              <Grid item xs={6} sm={3}>
                {/* className="text-left" */}
                {/* <Paper className={classes.paper} style={{height: '120px'}}> */}
                <FormControl component="fieldset">
                  <InputLabel htmlFor="select-autoclave">AUTOCLAVE</InputLabel>
                  <Select
                    style={{ minWidth: "120px" }}
                    native
                    labelId="select-autoclave"
                    id="select-autoclave"
                    value={whichAv}
                    onChange={(e) => setWhichAv(e.target.value)}
                  >
                    <option aria-label="None" value="" />
                    {autoclaves &&
                      autoclaves.map((item, index) => {
                        filterList = item;
                        return (
                          <option
                            key={filterList.id}
                            style={{
                              margin: "100px",
                              color: "#0a9396",
                              fontSize: "20px",
                              padding: "20px",
                            }}
                            value={filterList.id}
                          >
                            {filterList.name}
                          </option>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={3} className="text-left">
                {/* <Paper className={classes.paper} style={{height: '100px'}}> */}
                <FormControl className={classes.formControl}>
                  {/* <Typography style={{fontSize: '1.2rem'}}>Status</Typography> */}
                  <InputLabel htmlFor="select-current-status">
                    STATUS
                  </InputLabel>
                  {/* <InputLabel id="demo-simple-select-label" style={{fontSize : '2rem', marginBottom: '2rem'}}></InputLabel> */}
                  <Select
                    native
                    labelId="select-current-status"
                    id="select-current-status"
                    value={current_status}
                    onChange={(event) => set_current_status(event.target.value)}
                  >
                    <option aria-label="None" value="" />
                    <option value={"finished"}>Finished</option>
                    <option value={"in-use"}>In Use</option>
                    <option value={"load-removed"}>Load Removed</option>
                  </Select>
                </FormControl>
                {/* </Paper> */}
              </Grid>
              <Grid item xs={6} sm={3}>
                {/* <Paper className={classes.paper} style={{height: '120px'}}> className="text-left" */}
                <FormControl component="fieldset">
                  <InputLabel htmlFor="select-class5-status">
                    CLASS 5
                  </InputLabel>
                  <Select
                    style={{ minWidth: "120px" }}
                    native
                    labelId="select-class5-status"
                    id="select-class5-status"
                    value={type5_result}
                    onChange={(e) => set_type5_result(e.target.value)}
                  >
                    <option aria-label="None" value="" />
                    <option value={"1"}>Pass</option>
                    <option value={"0"}>Fail</option>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="flex-start"
              spacing={3}
              style={{ marginBottom: "10px" }}
              className="text-left"
            >
              <Grid item xs={6} sm={3}>
                {/* <Paper className={classes.paper} style={{height: '120px'}}> */}
                <FormControl component="fieldset">
                  {/* <FormLabel component="legend"  style={{fontSize : '1.5rem'}}>Biological Indicator</FormLabel> */}
                  <InputLabel htmlFor="select-bi-status">BIOLOGICAL</InputLabel>

                  <Select
                    style={{ minWidth: "160px" }}
                    native
                    labelId="select-bi-status"
                    id="select-bi-status"
                    value={bi_result}
                    onChange={(e) => set_bi_result(e.target.value)}
                  >
                    <option aria-label="None" value="" />
                    <option value={"1"}>Pass</option>
                    <option value={"0"}>Fail</option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={3}>
                {/* <Paper className={classes.paper} style={{height: '120px'}}> */}
                <FormControl component="fieldset">
                  <InputLabel htmlFor="select-c2-status">CLASS 2</InputLabel>

                  <Select
                    style={{ minWidth: "120px" }}
                    native
                    labelId="select-c2-status"
                    id="select-c2-status"
                    value={bowiedick_result}
                    onChange={(e) => set_bowiedick_result(e.target.value)}
                  >
                    <option aria-label="None" value="" />
                    <option value={"1"}>Pass</option>
                    <option value={"0"}>Fail</option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={3}>
                <FormControl component="fieldset">
                  <TextField
                    id="standard-full-width"
                    // style={{ margin: 8 }}
                    label="CYCLE NO"
                    value={cycle_no}
                    type="number"
                    onChange={(event) => set_cycle_no(event.target.value)}
                    // margin="normal"
                    // size= "small"
                  />
                </FormControl>
                {/* </Paper> */}
              </Grid>
            </Grid>
          </Paper>
        </Collapse>
      </div>
    );
  };

  return (
    <div>
      <div className={classes.root}></div>
      {showFilterDialog()}
    </div>
  );
}
