/* eslint-disable */
import { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import "../../styles/main-style.css";
import { Redirect } from "react-router-dom";
import { defaults } from "react-chartjs-2";
import SessionData from "../../services/session-data";
import axios from "axios";
import DateSelector from "./date-selector";
// Components
import SummaryCard from "./Charts/SummaryCard";
import AppBarAndDrawer from "./AppBarAndDrawer";
import { Link } from "react-router-dom";
import { Breadcrumbs, Typography } from "@material-ui/core";

const sd = new SessionData();
const API_URL = sd.getBaseURL();
// CSS
export const drawerWidth = "300";

type apiDataType = {
  av_name: string;
  total_loads: number;
};

type locLoadCount = {
  location: string;
  loc_id: number;
  load_count: number;
};

type locInsCount = {
  instrument_use_count: number;
};

export default function Dashboard() {
  const [isMobile, setIsMobile] = useState(false);
  const [urlChange, setUrlChange] = useState<boolean>(false);
  const [currentURL, setCurrentURL] = useState("");
  const [apiData, setApidata] = useState([]);
  const [APIUrl, setAPIUrl]: any = useState("web-reports/processlist");
  const [selectedDate, setSelectedDate] = useState<Date | string | null>(null);
  const [readerLocDate, setReaderLocDate] =
    useState<Date | string | null>(null);
  const [refreshChart, setRefreshChart] = useState(true);

  //store total loads data
  const [avLabels, setAvLabels] = useState([]);
  const [avCount, setAvCount] = useState([]);

  //sore reader location data
  const [location, setLocation] = useState([]);
  const [locationLoads, setLocationLoads] = useState([]);
  const [locationuseIns, setLocationuseIns] = useState([]);
  const [locFilterDate, setLocFilterDate] =
    useState<Date | string | null>(null);
  const [readerLocUrl, setReaderLocUrl] = useState("");
  const [refReaderChart, setRefReaderChart] = useState(false);

  // to get the count for the cards
  const [totalArticles, setTotalArticles] = useState("");
  const [totalAutoclaves, setTotalAutoclaves] = useState("");
  const [totalReaders, setTotalReaders] = useState("");
  const [totalPouch, setTotalPouch] = useState("");
  const [totalEmails, setTotalEmails] = useState("");
  const [totalBranches, setTotalBranches] = useState("");

  // Getting the window size
  useEffect(() => {
    window.screen.width <= 760 ? setIsMobile(true) : setIsMobile(false);
  }, [window.screen.width]);

  function detectWindowSize() {
    window.innerWidth <= 760 ? setIsMobile(true) : setIsMobile(false);
  }

  window.onresize = detectWindowSize;

  // Calling api's for the count for cards
  const getTotalArticles = () => {
    axios
      .get(API_URL + "web-reports/totalarticlescount", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sd.getClientToken(),
          "staff-auth": "Bearer " + sd.getStaffToken(),
        },
      })
      .then((result) => {
        if (result.status !== 200) throw new Error("Error");
        //get av names and total loads
        result.data.map((item) => {
          setTotalArticles(item.articleCount);
          setTotalAutoclaves(item.aclaveCount);
          setTotalBranches(item.branchCount);
          setTotalEmails(item.emailCount);
          setTotalPouch(item.pouchCount);
          setTotalReaders(item.readerCount);
        });
      })
      .catch(() => {
        setTotalArticles("");
      });
  };
  // Get Total Card Count
  useEffect(() => getTotalArticles(), []);
  useEffect(() => {
    makeAPICall();
  }, [selectedDate]);

  useEffect(() => {
    getReaderLocData();
  }, [locFilterDate]);

  const chartdata = () => {
    const data: any = {
      labels: avLabels,
      datasets: [
        {
          label: "No. of Loads",
          backgroundColor: "rgb(63, 81, 181)",
          borderColor: "rgb(255, 99, 132)",
          data: avCount,
        },
      ],
    };
    return data;
  };

  const readerLocationData = () => {
    const data: any = {
      labels: location,
      datasets: [
        {
          label: "Loads Done",
          backgroundColor: "rgb(233, 196, 106)",
          borderColor: "rgb(255, 99, 132)",
          data: locationLoads,
        },
        {
          label: "Articles Used",
          backgroundColor: "rgb(42, 157, 143)",
          borderColor: "rgb(255, 99, 132)",
          data: locationuseIns,
        },
      ],
    };
    return data;
  };

  //get the loads per date
  const makeAPICall = () => {
    setRefreshChart(false);
    const av_names: any = [];
    const av_count: any = [];
    axios
      .get(API_URL + "web-reports/loadcount?" + currentURL, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sd.getClientToken(),
            'staff-auth': "Bearer " + sd.getStaffToken()
        },
      })
      .then((result) => {
        if (result.status !== 200) throw new Error("Error");
        //get av names and total loads
        result.data.map((item) => {
          const eachItem: apiDataType = item;
          av_names.push(eachItem.av_name);
          av_count.push(eachItem.total_loads);
        });
        setAvLabels(av_names);
        setAvCount(av_count);
        setRefreshChart(true);
      })
      .catch(() => {
        setAvLabels([]);
        setAvCount([]);
        setRefreshChart(true);
      });
  };

  const getReaderLocData = () => {
    setRefreshChart(false);
    const loc_names: any = [];
    const loc_loads: any = [];
    const loc_ins: any = [];
    axios
      .get(API_URL + "web-reports/readerlocdata?" + readerLocUrl, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sd.getClientToken(),
          "staff-auth": "Bearer " + sd.getStaffToken(),
        },
      })
      .then((result) => {
        if (result.status !== 200) throw new Error("Error");
        //get reader location and load count

        result.data.loads.map((item) => {
          const eachItem: locLoadCount = item;

          loc_names.push(eachItem.location);
          loc_loads.push(eachItem.load_count);
        });
        // get use instrument count
        result.data.use_ins.map((item) => {
          const eachItem: locInsCount = item;
          loc_ins.push(eachItem.instrument_use_count);
        });

        setLocation(loc_names);
        setLocationLoads(loc_loads);
        setLocationuseIns(loc_ins);
        setRefreshChart(true);
      })
      .catch(() => {
        setLocation([]);
        setLocationLoads([]);
        setLocationuseIns([]);
        setRefreshChart(true);
      });
  };

  //callabck when changing the date for autoclave load chart
  const avLoadsDateCallback = (dateFormat: string) => {
    defaults.animation = false;
    setCurrentURL(`load_started_at=${dateFormat}`);
    setSelectedDate(dateFormat);
  };

  //callabck when changing the date for reader location chart
  const readerLocDateCallback = (dateFormat: string) => {
    defaults.animation = false;
    if (dateFormat != "") {
      setReaderLocUrl(`date=${dateFormat}`);
      setLocFilterDate(dateFormat);
    } else {
      setReaderLocUrl("");
      setLocFilterDate(dateFormat);
    }
  };

  const renderView = () => {
    return (
      <div
        className="container dFlex dFlexCol"
        style={{
          // width: "80%",
          height: "80%",
          justifyContent: "center",
        }}
      >
        <div className="row">
          {/* Chart 1 */}
          <div className="col-md-6 col-sm-12">
            <h5 className="mt-3 text-center">Autoclave Loads Report</h5>
            <div
              style={{ height: "auto" }}
              className="boxContainer shadow p-3 mb-5 bg-white rounded"
            >
              <DateSelector parentCallback={avLoadsDateCallback} />
              <div style={{ height: "250px" }}>
                {/* {refreshChart ?  */}
                <Bar
                  // type="bar"
                  options={{ maintainAspectRatio: false }}
                  data={chartdata}
                />
                {/* : '' } */}
              </div>
            </div>
          </div>
          {/* Chart 2 */}
          <div className="col-md-6 col-sm-12">
            <h5 className="mt-3 text-center">Reader Location Report</h5>
            <div
              style={{ height: "auto" }}
              className="boxContainer shadow p-3 mb-5 bg-white rounded"
            >
              <DateSelector parentCallback={readerLocDateCallback} />
              <div style={{ height: "250px" }}>
                {/* {refreshChart ?  */}
                <Bar
                  // type="bar"
                  options={{ maintainAspectRatio: false }}
                  data={readerLocationData}
                />
                {/* : ''}  */}
              </div>
            </div>
          </div>
        </div>
        {/* Cards */}
        <div className="row">
          <div className="col-md-4 col-sm-12">
            <SummaryCard
              title={"Total Used Articles"}
              value={totalArticles}
              component={undefined}
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <SummaryCard
              title={"AutoClaves Load"}
              value={totalAutoclaves}
              component={undefined}
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <SummaryCard
              title={"Readers Registered"}
              value={totalReaders}
              component={undefined}
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <SummaryCard
              title={"Total Pouch Items"}
              value={totalPouch}
              component={undefined}
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <SummaryCard
              title={"Registered Emails"}
              value={totalEmails}
              component={undefined}
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <SummaryCard
              title={"No. of Branches"}
              value={totalBranches}
              component={undefined}
            />
          </div>
        </div>
      </div>
    );
  };
  if (!isMobile) {
    return (
      <>
        <AppBarAndDrawer />
        <div style={{ marginLeft: "260px" }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" to="/">
              Home
            </Link>
            <Typography color="textPrimary">Dashboard</Typography>
          </Breadcrumbs>
          {renderView()}
        </div>
      </>
    );
  } else {
    return (
      <>
        <AppBarAndDrawer />
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" to="/">
            Home
          </Link>
          <Typography color="textPrimary">Dashboard</Typography>
        </Breadcrumbs>
        {renderView()}
      </>
    );
  }
}
