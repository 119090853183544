/* eslint-disable import/no-anonymous-default-export */
import { useState } from "react";
import SessionData from "../../services/session-data";
import axios from "axios";

export default () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState<boolean>(false);

  const request = async () => {
    const sd = new SessionData();
    setLoading(true);
    try {
      const result = await axios.get(
        sd.getBaseURL() + "web-standalone/get-staff",
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + sd.getClientToken(),
            "staff-auth": "Bearer " + sd.getStaffToken(),
          },
        }
      );
      setData(result.data);
    } catch (err: any) {
      setError(err.message || "Unexpected Error!");
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    error,
    loading,
    request,
  };
};
