/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import { useStyles } from "../../../Styles/mui-styles";
// import { convertToLocalTime } from "date-fns-timezone";
// import parseISO
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import Collapse from "@material-ui/core/Collapse";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import FilterListIcon from "@material-ui/icons/FilterList";
// Importing Libraries for the download
export default function AutoclaveFilterBar(props: any) {
  const [type, setType] = useState<String>("");
  const [usedBy, setUsedBy] = useState<String>("");
  const [dateUsed, setDateUsed] = useState<Date | String | null>(null);
  const [startDate, setStartDate] = useState<Date | String | null>(null);
  const [endDate, setEndDate] = useState<Date | String | null>(null);
  const [controlNo, setControlNo] = useState<String | Number>("");
  const [startDateChanged, setStartDateChanged] = useState<boolean>(false);
  const [endDateChanged, setEndDateChanged] = useState<boolean>(false);
  const [checked, setChecked] = React.useState(false);
  const [height, setHeight] = useState("0px");
  const classes = useStyles();

  const startHandleDateChange = (date: Date | null) => {
    if (date !== null && String(date) !== "") {
      setStartDateChanged(true);
      const startDateFormat =
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      setStartDate(startDateFormat);
    }
  };

  const endHandleDateChange = (date: Date | null) => {
    if (date !== null && String(date) !== "") {
      setEndDateChanged(true);
      const endDateFormat =
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      setEndDate(endDateFormat);
    }
  };

  /*React Dialog*/
  const [open, setOpen] = useState(false);

  useEffect(() => {
    {
      props.open ? setOpen(true) : setOpen(false);
    }
  }, []);

  useEffect(() => {
    props.parentCallback(type, usedBy, controlNo, dateUsed, startDate, endDate);
  }, [type, usedBy, controlNo, dateUsed, startDate, endDate]);

  //show or hide the filter bar
  const handleChange = () => {
    if (checked === false) {
      setChecked(true);
      setHeight("auto");
    } else {
      setHeight("0px");
      setChecked(false);
    }
  };

  const clearAllFilters = () => {
    setStartDateChanged(false);
    setEndDateChanged(false);
    setType("");
    setDateUsed(null);
    setEndDate(null);
    setStartDate(null);
    setControlNo("");
    setUsedBy("");
    setType("");
  };

  const showFilterDialog = () => {
    return (
      <>
        <Container style={{ maxWidth: "700px" }}>
          <Grid
            container
            spacing={3}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item sm={2} xs={2}>
              <FilterListIcon
                className="filterIcon"
                fontSize="large"
                onClick={handleChange}
              />
            </Grid>
            <Grid
              item
              style={{ marginTop: "10px", textAlign: "center" }}
              sm={6}
              xs={6}
            >
              <h2 className="jFontBold">Used Articles</h2>
            </Grid>
            <Grid item sm={2} xs={2}>
              <AutorenewIcon
                fontSize="large"
                className="refreshIcon"
                onClick={() => clearAllFilters()}
              />
            </Grid>
            {/* <Grid item sm={2} xs={2}>
              <ExportingDataFromCsv />
            </Grid> */}
          </Grid>
        </Container>
        <Collapse in={checked}>
          <Paper
            className={classes.paper}
            style={{
              height: height,
              marginTop: "20px",
              marginBottom: "20px",
              padding: "20px",
            }}
          >
            <Grid
              container
              justifyContent="space-between"
              spacing={3}
              style={{ marginBottom: "10px" }}
            >
              <Grid item xs={4} sm={2} className="text-left">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container>
                    <KeyboardDatePicker
                      disableToolbar
                      autoOk={true}
                      variant="inline"
                      label="Start"
                      format="yyyy-MM-dd"
                      // margin="normal"
                      id="date-picker-inline"
                      value={startDate}
                      onChange={startHandleDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
                {/* </Paper> */}
              </Grid>
              <Grid item xs={4} sm={2} className="text-left">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container>
                    <KeyboardDatePicker
                      disableToolbar
                      autoOk={true}
                      variant="inline"
                      label="End"
                      format="yyyy-MM-dd"
                      // margin="normal"
                      id="date-picker-inline"
                      value={endDate}
                      onChange={endHandleDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={4} sm={2} className="text-left">
                <FormControl component="fieldset">
                  <TextField
                    id="standard-full-width"
                    label="Control No"
                    value={controlNo}
                    type="number"
                    onChange={(event) => setControlNo(event.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={3} className="text-left">
                <FormControl component="fieldset">
                  <TextField
                    id="standard-full-width"
                    label="Article Name"
                    type="text"
                    value={type}
                    onChange={(event) => setType(event.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={3} className="text-left">
                <FormControl component="fieldset">
                  <TextField
                    id="standard-full-width"
                    label="Used By"
                    value={usedBy}
                    type="text"
                    onChange={(event) => setUsedBy(event.target.value)}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
        </Collapse>
      </>
    );
  };
  return (
    <>
      <div>
        <div className={classes.root}></div>
        {showFilterDialog()}
      </div>
    </>
  );
}
