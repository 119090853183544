/* eslint-disable no-lone-blocks */
/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { locData } from "./RegisterStaff";
import SessionData from "../../../services/session-data";
import SelectBranch from "../../Utils/SelectBranch";
import { Chip } from "@material-ui/core";
import { useState, useEffect } from "react";
import { useStyles } from "../../Styles/mui-styles";

export default function ModifyBranch(props: any) {
  const [open, setOpen] = useState(false);
  let data: locData = props.data;

  const sd = new SessionData();
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [branch, setBranch] = useState<string>("");
  const [pin, setPin] = useState<string>("");
  const [wristband, setWristband] = useState("");
  const [level, setLevel] = useState("");
  const classes = useStyles();
  const [usernameError, setUserNameError] = useState("");
  const [pinError, setPinError] = useState("");
  const [staffData, setStaffData] = useState<any>([]);
  const [dMessage, setDMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
    props.parentCallback("Information Not Updated!");
  };

  useEffect(() => {
    setFirstName(data.name);
    setLastName(data.name);
    setUsername(data.username);
    setBranch(data.branch_name);
    setPin(data.pin);
    setLevel(data.level);
    {
      props.editOpen ? setOpen(true) : setOpen(false);
    }
  }, []);
  // Getting all the staff Data
  const updateStaff = () => {
    axios
      .put(
        sd.getBaseURL() + "staff/updateStaff",
        {
          id: data.id,
          name: firstName,
          pin: pin,
          wristband: wristband,
          level: level,
          branch_id: localStorage.getItem("branchLocation"),
        },
        {
          method: "PUT",
          headers: {
            Authorization: "Bearer " + sd.getClientToken(),
            "staff-auth": "Bearer " + sd.getStaffToken(),
          },
        }
      )
      .then((data) => {
        if (data.status !== 200) throw new Error("List empty");
        setStaffData(data.data.data);
      })
      .catch((err) => {});
  };

  //check if username is already registered
  const handleUserName = (userName) => {
    setUsername(userName.toString().trim());
    if (
      staffData.filter(
        (item) => item.username.toLowerCase() === userName.toLowerCase()
      ).length > 0
    ) {
      setUserNameError("username already in use");
    } else {
      setUserNameError("");
    }
  };
  //------------------------
  //check if the length of pin
  const handlePin = (epin) => {
    setPin(epin);
    if (epin.length < 4) setPinError("Pin must be 4 to 6 charachets long");
    else setPinError("");
  };
  const showTheDialog = () => {
    return (
      <div>
        <Dialog
          fullWidth={true}
          maxWidth="md"
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">Edit Staff</DialogTitle>
          <DialogContent style={{ backgroundColor: "#eff9f0" }}>
            <DialogContentText>
              {/* showing articles in this load */}
              <form>
                <div className="form-group">
                  <div className="row">
                    <div className="col-12 mb-3">
                      <label htmlFor="username" className="form-label">
                        Username <span className="require-field">*</span>
                      </label>
                      <input
                        className="form-control"
                        id="username"
                        onChange={(e) => handleUserName(e.target.value)}
                        value={username}
                      />
                      <div
                        className={
                          usernameError.length > 0
                            ? "chip-list"
                            : "chip-list hide-me"
                        }
                      >
                        <Chip
                          color="secondary"
                          label={usernameError}
                          className={classes.chip}
                        />
                      </div>
                      {/* {usernameError} */}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-6">
                      <div>
                        <label htmlFor="firstName" className="form-label">
                          First Name <span className="require-field">*</span>
                        </label>
                        <input
                          className="form-control"
                          id="firstName"
                          onChange={(e) => setFirstName(e.target.value)}
                          value={firstName}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div>
                        <label htmlFor="lastName" className="form-label">
                          Last Name <span className="require-field">*</span>
                        </label>
                        <input
                          className="form-control"
                          id="lastName"
                          onChange={(e) => setLastName(e.target.value)}
                          value={lastName}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-6">
                      <label htmlFor="pin" className="form-label">
                        Pin <span className="require-field">*</span>
                      </label>
                      <input
                        className="form-control"
                        id="pin"
                        maxLength={6}
                        type="number"
                        onChange={(e) => handlePin(e.target.value)}
                        value={pin}
                      />
                      <div
                        className={
                          pinError.length > 0
                            ? "chip-list"
                            : "chip-list hide-me"
                        }
                      >
                        <Chip
                          color="secondary"
                          label={pinError}
                          className={classes.chip}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="wristbandtag" className="form-label">
                        Wristband Tagid
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="wristbandtag"
                        onChange={(e) => setWristband(e.target.value)}
                        value={wristband}
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="row mb-5">
                    <div className="col-md-6">
                      <label htmlFor="selectlevel">
                        User Level <span className="require-field">*</span>
                      </label>
                      <select
                        className="form-control"
                        id="selectlevel"
                        onChange={(e) => setLevel(e.target.value)}
                        value={level}
                      >
                        <option value=""></option>
                        <option value="user">User</option>
                        <option value="admin">Admin</option>
                      </select>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="selectlevel">
                        Branch Location <span className="require-field">*</span>
                      </label>
                      <SelectBranch />
                    </div>
                  </div>
                </div>
              </form>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ backgroundColor: "#eff9f0" }}>
            <Button onClick={formValidation} color="primary" autoFocus>
              Submit
            </Button>
            <Button onClick={handleClose} style={{ color: "red" }}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  //check if value is provided by user
  const formValidation = () => {
    //check if field values are valid
    if (usernameError.length <= 0 && pinError.length <= 0 && level) {
      //check other fields
      if (
        username.length <= 0 ||
        firstName.length <= 0 ||
        lastName.length <= 0 ||
        pin!.toString().length < 4 ||
        level.length <= 0 ||
        localStorage.getItem("branchLocation") == null
      ) {
        setDMessage("Please fill all the fields");
        setOpen(true);
        return;
      }
    } else {
      setDMessage("Please fill all the required fields");
      setOpen(true);
      return;
    }

    updateStaff();
    setLoading(true);
  };

  return <div>{open ? showTheDialog() : ""}</div>;
}
