/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import axios from "axios";
import SessionData from "../../../services/session-data";
import { useStyles } from "../../Styles/mui-styles";
import Container from "@material-ui/core/Container";
import "../../../styles/main-style.css";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import FailedBIReportDialog from "./failed-bi-report-dialog";
import AppBarAndDrawer from "../../Dashboard/AppBarAndDrawer";
import { Link } from "react-router-dom";
import { Breadcrumbs, Typography } from "@material-ui/core";

const sd = new SessionData();
const API_URL = sd.getBaseURL();

//response type
export type failedBIList = {
  id: number;
  name: string;
  st_id: number;
  cycle_start: number;
  cycle_end: number;
  callback_date: string;
};

export default function BiCallback() {
  const [response, setResponse] = useState([]);
  const [dialogInfo, setDialogInfo] = useState({});
  const [open, setOpen] = React.useState(false);

  const classes = useStyles();
  var getData: failedBIList;
  var filteredList: failedBIList;

  useEffect(() => {
    getFailedBIList();
  }, []);

  //API Call
  const getFailedBIList = () => {
    axios
      .get(API_URL + "web-reports/failedbilist", {
        method: "GET",
        headers: { Authorization: "Bearer " + sd.getClientToken() },
      })
      .then((result) => {
        if (result.status !== 200) throw new Error("Error");
        //storing response
        setResponse(result.data);
      })
      .catch((err) => {
        setResponse([]);
      });
  };

  //Handle the More info Click before opening dialog
  const handleInfoDialog = (e) => {
    response &&
      response.map((item, index) => {
        filteredList = item;
        if (index == e) {
          setDialogInfo(filteredList);
        }
      });
    setOpen(true);
  };

  //show the info dialog on View Details buton click
  const showInfoDialog = () => {
    return (
      <FailedBIReportDialog
        articleInfo={dialogInfo}
        open={true}
        parentCallback={handleCallback}
      />
    );
  };
  //callback from custom info dialog
  const handleCallback = (childData) => {
    setOpen(false);
  };

  return (
    <>
      <AppBarAndDrawer />
      <div style={{ marginLeft: "260px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" to="/">
            Home
          </Link>
          <Typography color="textPrimary">BI Callbacks</Typography>
        </Breadcrumbs>
        {/* <FailedBIReportDialog open={false} parentCallback={handleCallback} /> */}
        <Container maxWidth="lg" className="marginTop">
          <h3
            className="jFontBold"
            style={{ textAlign: "center", margin: "20px" }}
          >
            Failed BI Reports
          </h3>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Sterilizer</TableCell>
                  <TableCell>Cycle No</TableCell>
                  <TableCell>Callback Date</TableCell>
                  <TableCell>Details</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {response &&
                  response.map((item, index) => {
                    getData = item;
                    return (
                      <>
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>
                            <div style={{ fontSize: "20px" }}>
                              {getData.name}
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="cAvDate">{getData.cycle_end}</div>
                          </TableCell>
                          <TableCell>
                            <div className="cAvDate">
                              {getData.callback_date.split("T")[0]}
                            </div>
                          </TableCell>
                          <TableCell>
                            {" "}
                            <Button
                              className="cButton"
                              color="primary"
                              onClick={(e) =>
                                handleInfoDialog(e.currentTarget.value)
                              }
                              value={index}
                            >
                              view details
                            </Button>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
        {open ? showInfoDialog() : ""}
      </div>
    </>
  );
}
