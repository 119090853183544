/* eslint-disable no-lone-blocks */
import React, { useState, useEffect } from "react";
import axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { companyInfo } from "./GetCompanyInfo";
import SessionData from "../../services/session-data";

export default function DeleteUser(props: any) {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  let data: companyInfo = props.data;
  const [error, setError] = useState(false);
  let message = "";

  const handleClose = () => {
    setOpen(false);
    props.parentCallback(message);
  };
  const dialougeClose = () => {
    setOpen(false);
    props.parentCallback("User not Deleted");
  };

  useEffect(() => {
    {
      setName(data.name);
      setEmail(data.email);
      props.deleteOpen ? setOpen(true) : setOpen(false);
    }
  }, []);

  const DeleteUserInfo = async () => {
    const sd = new SessionData();
    try {
      const res = await axios.delete(sd.getBaseURL() + "user/companyinfo", {
        headers: {
          Authorization: "Bearer " + sd.getClientToken(),
          "staff-auth": "Bearer " + sd.getStaffToken(),
        },

        data: {
          id: data.id,
        },
      });
      if (res.status === 201) {
        message = "User Deleted";
      }
    } catch (err: any) {
      message = "Something Went Wrong";
    } finally {
      setName("");
      setEmail("");
      handleClose();
    }
  };

  const showTheDialog = () => {
    return (
      <div>
        <Dialog
          fullWidth={true}
          maxWidth="md"
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            Delete User Information
          </DialogTitle>
          <DialogContent style={{ backgroundColor: "#eff9f0" }}>
            <DialogContentText>
              {/* showing articles in this load */}
              <form>
                <div className="form-group">
                  <h4>Are you sure, you want to delete this user?</h4>
                </div>
                {error ? <div>Something Went Wrong!</div> : ""}
              </form>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ backgroundColor: "#eff9f0" }}>
            <Button onClick={formValidation} color="primary" autoFocus>
              Yes
            </Button>
            <Button onClick={dialougeClose} color="primary" autoFocus>
              No
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const formValidation = () => {
    if (name.length <= 0 || email.length <= 0 || data.id <= 0) {
      setError(true);
      return;
    }
    DeleteUserInfo();
  };

  return <div>{open ? showTheDialog() : ""}</div>;
}
