import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 10,
    },
    chip: {
      margin: theme.spacing(0.5),
      fontSize: '16px',
    },
    section1: {
      margin: theme.spacing(1, 2, 1, 2),
    },
    section2: {
      margin: theme.spacing(0.5, 2, 0.5, 2),
    },
    section3: {
      margin: theme.spacing(3, 1, 1),
    },
  }),
);

export default function ListView(props: any) {
  const classes = useStyles();

  return (
    <Container maxWidth="lg" >
      <div className={classes.root}>
        <div className={classes.section1}>
          <Grid container alignItems="center"  style={{border: '1px solid #ced4da', borderRadius: '10px', padding: '8px'}}>
            <Grid item  xs={12} sm={4}>
              <Typography variant="body1" style={{fontWeight: 'bold'}}>
                {props.name}
              </Typography>
            </Grid>
            <Grid item  xs={12} sm={4}>
              <Typography variant="h6">
                <Chip className={classes.chip} label={`Control No ${props.control}`}/>
              </Typography>
            </Grid>
            <Grid item  xs={12} sm={4} style={{ display: 'flex', justifyContent: 'center' }}>

              <Chip color="primary" label={props.status}
                style={{ fontWeight: 'bold',
                  backgroundColor: `${props.status.toLowerCase() === "available" ? "#009688"
                    : props.status.toLowerCase() === "used" ? "#673ab7" : props.status.toLowerCase() === "" ? "#eff9f0" 
                      : "#f44336"}`
                }} />
            </Grid>

          </Grid>
        </div>
      </div>
    </Container>
  );
}