import React, { useState } from "react";
import axios from "axios";
import SessionData from "../../services/session-data";
import { Breadcrumbs, Paper, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Loader from "react-loader-spinner";
import Button from "@material-ui/core/Button";
import AppBarAndDrawer from "../Dashboard/AppBarAndDrawer";
import { Link } from "react-router-dom";
export default function CheckTagStatus() {
  const [open, setOpen] = React.useState(false);
  const [dMessage, setDMessage] = React.useState("");
  const [loading, setLoading] = useState(false);
  const [enterId, setEnterId] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [list, setList] = useState([]);

  const sd = new SessionData();

  const handleClose = () => {
    setOpen(false);
  };

  const formValidation = () => {
    if (enterId == null) {
      setDMessage("Please Scan the required Item(s)");
      setOpen(true);
      return;
    } else if (enterId.length <= 0) {
      setDMessage("Please Scan the required Item(s)");
      setOpen(true);
      return;
    }
    checkTagStatus();
    setLoading(true);
  };

  //Register Article Type Request
  const checkTagStatus = () => {
    axios
      .get(sd.getBaseURL() + `web-standalone/check-status/${enterId}`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sd.getClientToken(),
          "staff-auth": "Bearer " + localStorage.getItem("staffAuthToken"),
        },
      })
      .then((result) => {
        if (result.status !== 200) {
          setDMessage("Scan Item(s) not Found");
          setLoading(false);
          setOpen(true);
          throw new Error("Scan Item(s) not Found");
        }
        setDMessage("Found the required Information");
        setOpen(true);
        setLoading(false);
        setList(result.data[0]);
      })
      .catch((err) => {
        setLoading(false);
        setDMessage("Scan Item(s) not Found");
        setOpen(true);
      });
  };
  //-------------------------------
  const errorDialog = () => {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Update</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  //-------------------------------

  return (
    <>
      <AppBarAndDrawer />
      <div style={{ marginLeft: "260px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" to="/">
            Home
          </Link>
          <Typography color="textPrimary">Check Status</Typography>
        </Breadcrumbs>
        <div
          className="container dFlex dFlexCol"
          style={{ width: "100%", height: "80%", justifyContent: "center" }}
        >
          <h2 className="text-center jFontBold color-blue mt-4 mb-4">
            Check Status
          </h2>
          <Paper
            className="jPadding dFlex box-shadow"
            style={{ justifyContent: "center", width: "auto", margin: "0 0" }}
          >
            <form>
              <div>
                <div className="row mb-3">
                  <div className="col-12 col-sm-12" style={{ display: "flex" }}>
                    <div>
                      <label htmlFor="checkStatus" className="form-label h4">
                        Please Scan your Instrument below to check Status!
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setEnterId(e.target.value)}
                        value={enterId}
                        autoComplete="false"
                        id="checkStatus"
                      />
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <button
                    type="button"
                    onClick={formValidation}
                    className="btn btnColor btn-lg text-center"
                  >
                    Check Status
                  </button>
                </div>
              </div>
            </form>
            <div className="row"></div>
          </Paper>
          {loading ? (
            <div className="jCentered">
              <Loader
                type="Oval"
                color="#3f51b5"
                height={70}
                width={70}
                timeout={10000} //10 secs
              />{" "}
            </div>
          ) : (
            ""
          )}
        </div>
        {open === true ? errorDialog() : ""}
      </div>
    </>
  );
}
