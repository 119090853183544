/* eslint-disable */
import { useState, useEffect } from "react";
import validator from "validator";
import { useHistory, Link } from "react-router-dom";
import axios from "axios";
import SessionData from "../../services/session-data";
import logoImage from "../../images/reprolog_logo.png";

export default function PasswordResetRequest() {
  const sd = new SessionData();
  const API_URL = sd.getBaseURL();

  //client login response type
  type passwordReset = {
    email: string;
    cid: string;
  };

  const [clientid, setClientid] = useState<any>("");
  const [username, setUsername] = useState("");
  var query = "";
  const [login, setLogin] = useState("");
  const [showInfo, setShowInfo] = useState(false);
  const [message, setMessage] = useState("");
  let history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("clientName") != null)
      setClientid(localStorage.getItem("clientName"));
  }, []);

  const validateFields = (e) => {
    e.preventDefault();
    if (
      clientid.length > 0 &&
      username.length > 0 &&
      validator.isEmail(username)
    ) {
      query = `email=${username}&cid=DENTAL_${clientid.toUpperCase()}`;
      handleReset();
    } else {
      setMessage("Please enter email and client name");
      setShowInfo(true);
    }
  };

  //Reset the Password
  const handleReset = () => {
    axios
      .get(API_URL + "user/reset-password?" + query, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((data) => {
        if (data.status == 200) {
          setMessage(
            "Please follow the instructions in your email to reset the Password"
          );
          setShowInfo(true);
        } else if (data.status == 400) {
          setMessage("Request Failed. Please check your email and client name");
          setShowInfo(true);
        }
      })
      .catch((error) => {
        setMessage(error.response.data.errorDetails);
        setShowInfo(true);
      });
  };
  return (
    <div className="container-fluid login-bg">
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex flex-column min-vh-100  align-items-center ">
            <div className="main_logo_client">
              <img src={logoImage} />
              <div className="cloginTitle">
                <h1>Password Reset Request</h1>
              </div>
            </div>
            <div className="clientLogin">
              {login ? "Login Successfull" : ""}
              <form>
                <div className="form-group">
                  <label htmlFor="inputClientId">Client id</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setClientid(e.target.value)}
                    value={clientid}
                    id="inputClientId"
                    placeholder="client id"
                  ></input>
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Email address</label>
                  <input
                    type="email"
                    className="form-control"
                    onChange={(e) => setUsername(e.target.value)}
                    value={username}
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter email"
                  ></input>
                </div>
                <button
                  type="submit"
                  onClick={(e) => validateFields(e)}
                  className="btn btn-danger btn-lg btn-block"
                >
                  Reset Password
                </button>

                <p style={{ marginTop: "20px", width: "200px" }}>
                  <Link to="/main-login">Login?</Link>{" "}
                </p>
                {showInfo ? (
                  <p style={{ textAlign: "center" }}>{message}</p>
                ) : (
                  ""
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
