/* eslint-disable */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Redirect, Link, useHistory } from "react-router-dom";
import SessionData from "../../../services/session-data";
import Rodal from "rodal";
import { useStyles } from "../../Styles/mui-styles";
import "../../../styles/main-style.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import Header from "../../NavBar/header";
import Paper from "@material-ui/core/Paper";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import SelectBranch from "../../Utils/SelectBranch";
import Container from "@material-ui/core/Container";
import addIcon from "../../../images/add-icon.svg";
import AppBarAndDrawer from "../../Dashboard/AppBarAndDrawer";
import { Breadcrumbs, Typography } from "@material-ui/core";

export type autoclaveListRes = {
  id: number;
  autoclave_name: string;
  autoclave_model: string | null;
  autoclave_manufacturer: string | null;
  serial_no: string | null;
  bowie_dick: string;
  autoclave_opname: string;
};

export default function RegisterAutoclave() {
  let listData: autoclaveListRes;
  const [avName, setAvName] = useState("");
  const [autoclaveOpName, setAutoclaveOpname] = useState("");
  const [cycleNo, setCycleNo] = useState("");
  const [description, setDescription] = useState("");
  const [newAvId, setNewAvId] = useState(0);
  const [manuf, setManuf] = useState("");
  const [model, setModel] = useState("");
  const [serialNo, setSerialNo] = useState("");
  const [bowieDick, setBowieDick] = useState("");
  const [autoclavesList, setAutoclavesList] = useState([]);
  const [dialog, setDialog] = useState("hidden");
  const [open, setOpen] = React.useState(false);
  const [dMessage, setDMessage] = React.useState("");
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();
  const formValidation = () => {
    if (
      avName.length <= 0 ||
      cycleNo.length <= 0 ||
      bowieDick.length <= 0 ||
      localStorage.getItem("branchLocation") == null
    ) {
      setDMessage("Please Select Autoclave, cycle and branch to register");
      setOpen(true);
      return;
    }
    registerAv();
    setLoading(true);
  };

  useEffect(() => {
    getAutoclavesList();
  }, []);

  const registerAv = () => {
    const sd = new SessionData();
    setDialog("hidden");
    axios
      .post(
        sd.getBaseURL() + "web-register/autoclave",
        {
          avName: avName,
          description: description,
          manuf: manuf,
          model: model,
          serialNo: serialNo,
          bowieDick: bowieDick,
          cycleNo: cycleNo,
          avId: newAvId,
          autoclaveOpName: autoclaveOpName,
          branch_id: localStorage.getItem("branchLocation"),
        },
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + sd.getClientToken(),
            "staff-auth": "Bearer " + sd.getStaffToken(),
          },
        }
      )
      .then((data) => {
        if (data.status !== 201) {
          setDMessage("Autoclave Registration Failed");
          setLoading(false);
          setOpen(true);
          throw new Error("not able to register the autoclave");
        }

        setDMessage("New Autoclave Registered!");
        setOpen(true);

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setDMessage("Autoclave Registration Failed!");
        setOpen(true);
      });
  };

  const getAutoclavesList = () => {
    const sd = new SessionData();
    axios
      .get(sd.getBaseURL() + "web-register/getautoclaves", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sd.getClientToken(),
        },
      })
      .then((data) => {
        if (data.status !== 200) throw new Error("autoclaves not found");
        setAutoclavesList(data.data);
      })
      .catch((err) => {});
  };

  const checkUserLogin = () => {
    const sd = new SessionData();
    if (!sd.getStaff()) return <Redirect exact to="/staff-login" />;
  };

  const myStyles = {
    alignSelf: "center",
    display: "inline-block",
    marginTop: 20,
    padding: 5,
    borderRadius: 5,
    backgroundColor: "#9e2a2b",
    color: "white",
    fontSize: 20,
    visibility: dialog,
  } as React.CSSProperties;

  const errorDialog = () => {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Autoclave</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const setAutoclaveParams = (avId: string) => {
    {
      autoclavesList &&
        autoclavesList.map((item, index) => {
          listData = item;
          if (listData.id.toString() === avId) {
            setAvName(listData.autoclave_name);
            setAutoclaveOpname(listData.autoclave_opname);
            setDescription("");
            setManuf(
              listData.autoclave_manufacturer != null
                ? listData.autoclave_manufacturer
                : ""
            );
            setModel(
              listData.autoclave_model != null ? listData.autoclave_model : ""
            );
            setSerialNo(listData.serial_no != null ? listData.serial_no : "");
            setBowieDick(listData.bowie_dick);
            setNewAvId(listData.id);
          }
        });
    }
  };

  function handleClick() {
    history.push(`/autoclaves/register-newautoclave`);
  }

  return (
    <>
      <AppBarAndDrawer />
      <div style={{ marginLeft: "260px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" to="/">
            Home
          </Link>
          <Typography color="textPrimary">Register Autoclave</Typography>
        </Breadcrumbs>
        {/* <div className="container jMarginTop50" style={{width: '80%', height: '80%'}}> */}
        <div
          className="container dFlex dFlexCol"
          style={{ width: "80%", height: "80%", justifyContent: "center" }}
        >
          <Container maxWidth="lg" className="marginTop">
            <div
              className="row"
              style={{
                textAlign: "center",
                marginRight: "50px",
                marginBottom: "10px",
              }}
            >
              <div className="col-11">
                {" "}
                <h2 className="jFontBold">Register New Autoclave</h2>
              </div>
              <div className="col-1">
                <Button
                  style={{
                    borderRadius: 10,
                    backgroundColor: "#1565c0",
                    fontSize: "16px",
                    color: "#FFFFFF",
                  }}
                  variant="contained"
                  size="medium"
                  onClick={handleClick}
                >
                  Add New Autoclave
                </Button>
                {/* <img className="imageLogoSmall" src={addIcon} /> */}
              </div>
            </div>
          </Container>

          <Paper className="jPadding">
            <form>
              <div className="form-group">
                <label htmlFor="selectAutoclave">Select Autoclave</label>
                <select
                  className="form-control"
                  id="selectAutoclave"
                  onChange={(e) => setAutoclaveParams(e.target.value)}
                >
                  <option></option>
                  {autoclavesList &&
                    autoclavesList.map((item, index) => {
                      listData = item;
                      return (
                        <option
                          style={{
                            margin: "100px",
                            color: "#0a9396",
                            fontSize: "20px",
                            padding: "20px",
                          }}
                          value={listData.id}
                        >
                          {listData.autoclave_name}{" "}
                          {listData.autoclave_opname
                            ? "(" + listData.autoclave_opname + ")"
                            : ""}
                          : Model - {listData.autoclave_model}{" "}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="row">
                <div className="form-group col-md-4 col-sm-12">
                  <label htmlFor="branchlocation">Branch Location</label>
                  <SelectBranch />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-4 col-sm-12">
                  <label htmlFor="cycleNumber">Cycle No</label>
                  <input
                    type="number"
                    className="form-control"
                    min={0}
                    required
                    onChange={(e) => {
                      setCycleNo(e.target.value);
                    }}
                    value={cycleNo}
                    id="cycleNumber"
                    placeholder="cycle #"
                  ></input>
                </div>
              </div>

              <button
                type="button"
                onClick={formValidation}
                className="btn btnColor btn-lg"
              >
                Register Autoclave
              </button>
            </form>
            <div className="row"></div>
          </Paper>
          {loading ? (
            <div className="jCentered">
              <Loader
                type="Oval"
                color="#3f51b5"
                height={70}
                width={70}
                timeout={10000} //10 secs
              />{" "}
            </div>
          ) : (
            ""
          )}
        </div>
        {open === true ? errorDialog() : ""}
      </div>
    </>
  );
}
