/* eslint-disable */
import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import { TextField, Button } from "@material-ui/core";
import SessionData from "../../services/session-data";

const sd = new SessionData();
const API_URL = sd.getBaseURL();

type controlReportType = {
  article_type: string;
  type: string;
  article_tag_id: string;
  control_no: number;
  loaded_by: "Jagtar Singh";
  cycle_no: 75;
  load_started_by: "Jagtar Singh";
  load_finished_at: "2021-04-30T20:21:44.000Z";
  name: "MIDMARK M11";
  used_by: null;
  used_date: null;
};
export default function ControlReport() {
  const [controlNo, setControlNo] = useState<number | null>();
  const [controlReport, setControlReport] = useState([]);

  const checkUserLogin = () => {
    const sd = new SessionData();
    if (!sd.getStaff()) return <Redirect exact to="/staff-login" />;
  };

  const getControlReport = (e) => {
    e.preventDefault();
    axios
      .get(API_URL + "staff-a/web-reports/controlreport/" + controlNo, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("authToken"),
          "staff-auth": "Bearer " + localStorage.getItem("staffAuthToken"),
        },
      })
      .then((data) => {
        if (data.status === 200) {
          setControlReport(data.data);

          return <div>{data.data}</div>;
        } else throw new Error("Error");
      })
      .catch((error) => {});
  };

  return (
    <div className="container">
      {/* checking if user is logged in */}

      <div className="row">
        <h4>Enter Control No</h4>
      </div>
      <div className="row">
        <div className="col-3">
          <form noValidate autoComplete="off">
            <TextField
              id="standard-basic"
              value={controlNo}
              type="number"
              onChange={(e) => setControlNo(parseInt(e.target.value))}
              label="control #"
            />
          </form>
        </div>
        <div className="col3">
          <Button
            variant="contained"
            type="submit"
            onClick={(e) => getControlReport(e)}
            color="primary"
          >
            Search
          </Button>
        </div>
        {controlReport &&
          controlReport.map((rp, index) => {
            const getData: controlReportType = rp;
            return (
              <tr key={index}>
                <td>{getData.name}</td>
                <td>{getData.type}</td>
                <td>{getData.control_no}</td>
                <td>{getData.cycle_no}</td>
                <td>{getData.load_started_by}</td>
                <td>{getData.used_by}</td>
                <td>{getData.used_date}</td>
              </tr>
            );
          })}
      </div>
    </div>
  );
}
