/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../styles/main-style.css";
import SessionData from "../../services/session-data";
import axios from "axios";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    linkBg: {
      textDecoration: "none",
      color: "#ffffff",
      fontSize: 16,
    },
  })
);

export default function Header(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [title, setTitle] = useState("");
  const [clientName, setClientName] = useState<String | null>("");

  //So I go to phpmyadmin to check what
  //rfid tag ids
  //

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const checkUserLogin = () => {
    const sd = new SessionData();
    // sd.logOutStaff();
    sd.logOutClient();
  };

  const loginLogout = () => {
    if (localStorage.getItem("authToken") != null) {
      return localStorage.getItem("authToken")!.length <= 0 ? (
        <Link to="/main-login">
          <p onClick={() => checkUserLogin()}>Log In</p>
        </Link>
      ) : (
        <Link to="/main-login">
          <p onClick={() => checkUserLogin()}>Log out</p>
        </Link>
      );
    } else {
      return (
        <Link to="/main-login">
          <p onClick={() => checkUserLogin()}>Log In</p>
        </Link>
      );
    }
  };

  const changeTitle = (event) => {
    setTitle(event.currentTarget.innerHTML);
  };

  //get client info by RP
  useEffect(() => {
    getClientInfo();
  }, []);

  const getClientInfo = () => {
    const sd = new SessionData();
    axios
      .get(sd.getBaseURL() + "user/companyinfo", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + sd.getClientToken(),
        },
      })
      .then((data) => {
        if (data.status !== 200) {
          return;
        }
        setClientName(localStorage.getItem("clientName"));
      })
      .catch((err) => {
      });
  };

  return (
    <>
      <div
        className="container-fluid p-0"
        style={{ zIndex: 1, position: "relative" }}
      >
        <nav className="navbar navbar-expand-md navColor navbar-light">
          <a className="navbar-brand text-white" href="#">
           {clientName}
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ml-auto mainNavBar">
              <li className="nav-item active">
                <div className="nav-link">
                  <Link to="/">
                    <p onClick={changeTitle}>Home</p>
                  </Link>
                  <span className="sr-only">(current)</span>
                </div>
              </li>

              <li className="nav-item">
                <div className="nav-link">
                  {" "}
                  <Link to="/autoclaves">
                    <p onClick={changeTitle}>Autoclaves</p>
                  </Link>
                </div>
              </li>
              <li className="nav-item">
                <div className="nav-link">
                  {" "}
                  <Link to="/autoclave-process">
                    <p onClick={changeTitle}>Loads</p>
                  </Link>
                </div>
              </li>
              <li
                className="nav-item dropdown"
                style={{ zIndex: 1, position: "relative" }}
              >
                <a
                  style={{ color: "white" }}
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Register
                </a>
                <div
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdown"
                  style={{
                    zIndex: 100,
                    position: "fixed",
                    right: "auto",
                    bottom: "auto",
                    top: "auto",
                    left: "auto",
                  }}
                >
                  <Link to="/register-branch" className="dropdown-item">
                    <p onClick={changeTitle} style={{ color: "black" }}>
                      Register Branch
                    </p>
                  </Link>
                  <Link to="/register-reader" className="dropdown-item">
                    <p onClick={changeTitle} style={{ color: "black" }}>
                      Register Reader
                    </p>
                  </Link>
                  <Link to="/register-staff" className="dropdown-item">
                    <p onClick={changeTitle} style={{ color: "black" }}>
                      Register Staff
                    </p>
                  </Link>
                  <Link
                    to="/autoclaves/register-autoclave"
                    className="dropdown-item"
                  >
                    <p onClick={changeTitle} style={{ color: "black" }}>
                      Register Autoclave
                    </p>
                  </Link>
                  <Link to="/register-article" className="dropdown-item">
                    <p onClick={changeTitle} style={{ color: "black" }}>
                      Register Article
                    </p>
                  </Link>
                  <Link to="/register-article-type" className="dropdown-item">
                    <p onClick={changeTitle} style={{ color: "black" }}>
                      Register Article Type
                    </p>
                  </Link>
                  <Link to="/register-bilot" className="dropdown-item">
                    <p onClick={changeTitle} style={{ color: "black" }}>
                      Register BI LOT
                    </p>
                  </Link>
                  {/* <Link to="/used-articles" className="dropdown-item">
                    <p onClick={changeTitle} style={{ color: "black" }}>
                      Used Articles
                    </p>
                  </Link> */}
                </div>
              </li>
              <div className="nav-link">
                {" "}
                <Link to="/failed-bi-list">
                  <p onClick={changeTitle}>BI Callback</p>
                </Link>
              </div>
              <li className="nav-item">
                <div className="nav-link">{loginLogout()}</div>
              </li>
            </ul>
          </div>
        </nav>

        {/* <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>Profile</MenuItem>
              <MenuItem onClick={handleClose}>Log out</MenuItem>
            </Menu>
          </div> */}
      </div>
    </>
  );
}
