/* eslint-disable */
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  withRouter,
  Redirect,
  RouteComponentProps,
} from "react-router-dom";
import SessionData from "../../../services/session-data";
import ListView from "../../../containers/ListItem/list-view";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import "../../../styles/main-style.css";
import Typography from "@material-ui/core/Typography";
import Header from "../../NavBar/header";
import Paper from "@material-ui/core/Paper";
import { useStyles } from "../../Styles/mui-styles";
import { processListType } from "../../AutoclaveLoads/ShowAutoclaveLoads";
import Brightness1Icon from "@material-ui/icons/Brightness1";
const sd = new SessionData();
const API_URL = sd.getBaseURL();
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

//defining type for autoclave process
type itemListType = {
  Name: string;
  Loaded_in: string;
  a_type: string;
  article_tag_id: string;
  is_used: string;
  is_failed: string;
  Processed_on: Date;
  control_no: number | null;
  current_status: string;
};

function GetArticlesPerLoad(props: any) {
  const [itemList, setItemList] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [imageURL, setImageURL] = useState<string>();
  // const [avInfo, setAvInfo]: any = useState<processListType>();
  var avInfo: processListType = props.info;
  const classes = useStyles();
  useEffect(() => {
    avInfo = props.info;
    handleShowItemList();
    return () => {};
  }, []);

  const checkUserLogin = () => {
    const sd = new SessionData();
    if (!sd.getStaff()) return <Redirect exact to="/staff-login" />;
  };

  /***************API CALL */
  const handleShowItemList = () => {
    // axios.get(API_URL + `staff-a/web-reports/itemslist/${match.params.id}`, {
    axios
      .get(API_URL + `web-reports/itemslist/${props.id}`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("authToken"),
          "staff-auth": "Bearer " + localStorage.getItem("staffAuthToken"),
        },
      })
      .then((result) => {
        if (result.status !== 200) throw new Error("Error");
        setItemList(result.data);
      })
      .catch((error) => {});
  };
  /***************API CALL */

  const handleClose = () => {
    setOpen(false);
  };

  const setImageToShow = (url: string) => {
    setImageURL(url);
    setOpen(true);
  };

  const imageDialog = () => {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Autoclave</DialogTitle>
        <DialogContent>
          <img
            width="500px"
            height="500px"
            src={API_URL + `images/` + imageURL}
          ></img>
          {/* <DialogContentText id="alert-dialog-description">
      {dMessage}
      
      </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <div className="container">
        <h5 className="py-2 text-center">Autoclave Details</h5>
        <div className="row justify-content-md-center">
          <div className="col-md-3 col-sm-6">
            <Paper
              className={classes.paperback}
              style={{ textAlign: "center" }}
            >
              <p className="fw-500">Autocalve name</p>
              <p>{avInfo.Autoclave}</p>
            </Paper>
          </div>
          <div className="col-md-3 col-sm-6">
            <Paper
              className={classes.paperback}
              style={{ textAlign: "center" }}
            >
              <p className="fw-500">Cycle No</p>
              <p>
                {avInfo.current_status !== "load-removed"
                  ? avInfo.cycle_no
                  : "_"}
              </p>
            </Paper>
          </div>
          <div className="col-md-3 col-sm-6">
            <Paper
              className={classes.paperback}
              style={{ textAlign: "center" }}
            >
              <p className="fw-500">Load Date</p>
              <p>
                {avInfo.load_started_at !== null
                  ? avInfo.load_started_at!.split("T")[0]
                  : "Not Finished"}
              </p>
            </Paper>
          </div>
          <div className="col-md-3 col-sm-6">
            <Paper
              className={classes.paperback}
              style={{ textAlign: "center" }}
            >
              <p className="fw-500">Load By</p>
              <p>{avInfo.started_by === null ? "_" : avInfo.started_by}</p>
            </Paper>
          </div>
        </div>
        <h5 className="py-2 my-2 text-center">Chemical Indicators</h5>
        <div className="row justify-content-md-center">
          <div
            className={`${
              avInfo.type5_result == null
                ? "roundIconHide"
                : "col-md-3 col-sm-6"
            }`}
          >
            <Paper
              className={classes.paperback}
              style={{ textAlign: "center" }}
            >
              <p className="fw-500">
                Class5{" "}
                <span>
                  <Brightness1Icon
                    className={`${
                      avInfo.type5_result == null
                        ? "roundIconHide"
                        : avInfo.type5_result === 1
                        ? "roundIcon"
                        : "roundIconRed"
                    }`}
                  />
                </span>{" "}
              </p>
              <p>
                By:{" "}
                {avInfo.type5_result_by === null
                  ? "_"
                  : avInfo.type5_result_by?.split(" ")[0]}
              </p>
              {avInfo.type5_image_url === null ? (
                ""
              ) : avInfo.type5_image_url === "nophoto" ? (
                ""
              ) : (
                <a
                  href="#"
                  onClick={() => setImageToShow(avInfo.type5_image_url)}
                  className="link-info"
                >
                  <p>view photo</p>
                </a>
              )}
            </Paper>
          </div>
          <div
            className={`${
              avInfo.bi_result == null ? "roundIconHide" : "col-md-3 col-sm-6"
            }`}
          >
            <Paper
              className={classes.paperback}
              style={{ textAlign: "center" }}
            >
              <p className="fw-500">
                BI{" "}
                <span>
                  <Brightness1Icon
                    className={`${
                      avInfo.bi_result == null
                        ? "roundIconHide"
                        : avInfo.bi_result === 1
                        ? "roundIcon"
                        : "roundIconRed"
                    }`}
                  />
                </span>{" "}
              </p>
              <p>
                By:{" "}
                {avInfo.bi_result_by === null
                  ? "_"
                  : avInfo.bi_result_by?.split(" ")[0]}
              </p>
              {avInfo.bi_image_url === null ? (
                ""
              ) : avInfo.bi_image_url === "nophoto" ? (
                ""
              ) : (
                <a
                  href="#"
                  onClick={() => setImageToShow(avInfo.bi_image_url)}
                  className="link-info"
                >
                  <p>view photo</p>
                </a>
              )}
            </Paper>
          </div>
          <div
            className={`${
              avInfo.bowiedick_result == null
                ? "roundIconHide"
                : "col-md-3 col-sm-6"
            }`}
          >
            <Paper
              className={classes.paperback}
              style={{ textAlign: "center" }}
            >
              <p className="fw-500">
                Class2{" "}
                <span>
                  <Brightness1Icon
                    className={`${
                      avInfo.bowiedick_result == null
                        ? "roundIconHide"
                        : avInfo.bowiedick_result === 1
                        ? "roundIcon"
                        : "roundIconRed"
                    }`}
                  />
                </span>{" "}
              </p>
              <p>
                By: {avInfo.c2_loaded_by === null ? "_" : avInfo.c2_loaded_by}
              </p>
              {avInfo.bowiedick_image_url === null ? (
                ""
              ) : avInfo.bowiedick_image_url === "nophoto" ? (
                ""
              ) : (
                <a
                  href="#"
                  onClick={() => setImageToShow(avInfo.bowiedick_image_url)}
                  className="link-info"
                >
                  <p>view photo</p>
                </a>
              )}
            </Paper>
          </div>
        </div>
        <h5 className="py-2 my-2 text-center">Autoclave Parameters</h5>
        <div className="row justify-content-md-center">
          <div className="col-md-3 col-sm-6">
            <Paper
              className={classes.paperback}
              style={{ textAlign: "center" }}
            >
              <p className="fw-500">Temprature</p>
              <p>{avInfo.temprature}</p>
            </Paper>
          </div>
          <div className="col-md-3 col-sm-6">
            <Paper
              className={classes.paperback}
              style={{ textAlign: "center" }}
            >
              <p className="fw-500">Pressure</p>
              <p>{avInfo.pressure}</p>
            </Paper>
          </div>
          <div className="col-md-3 col-sm-6">
            <Paper
              className={classes.paperback}
              style={{ textAlign: "center" }}
            >
              <p className="fw-500">Duration</p>
              <p>{avInfo.duration}</p>
            </Paper>
          </div>
        </div>
      </div>
      <Container maxWidth="lg">
        {/* List Item */}
        <Grid container alignItems="center">
          <Grid item xs={12}>
            <h5 className="py-2 mt-2 text-center">Articles</h5>

            <div className="aritclesList">
              {itemList &&
                itemList.map((item, index) => {
                  const getData: itemListType = item;
                  return (
                    // Using list view component here

                    <ListView
                      name={getData.Name}
                      control={
                        getData.control_no === null ? " " : getData.control_no
                      }
                      status={
                        getData.Name !== "CLASS-5" &&
                        getData.Name !== "CLASS-2" &&
                        getData.Name.split(" ")[0] !== "BIOLOGICAL-INDICATOR"
                          ? getData.current_status === "load-removed"
                            ? "Removed"
                            : getData.is_used === "yes"
                            ? "Used"
                            : getData.is_failed === "yes"
                            ? "Failed"
                            : "Available"
                          : ""
                      }

                      // date={getData.Processed_on.toString().split("T")[0]}
                    />
                  );
                })}
            </div>
          </Grid>
        </Grid>
        {open === true ? imageDialog() : ""}
      </Container>
    </>
  );
}

export default withRouter(GetArticlesPerLoad);
