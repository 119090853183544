import React from "react";

export default class SessionData {
  //setting client token and name here
  setAuthToken = (token: string, clientName: string) => {
    localStorage.setItem("authToken", token);
    localStorage.setItem("client", clientName);
  };

  //setting staff Token
  setStaffToken = (sToken: string) => {
    localStorage.setItem("staffAuthToken", sToken);
  };

  ///check client
  getClient = (): boolean => {
    const ls = localStorage.getItem("authToken");
    if (ls == null || ls.length <= 0) return false;
    return true;
  };

  //check staff
  getStaff = (): boolean => {
    const ls = localStorage.getItem("staffAuthToken");
    if (ls == null || ls.length <= 0) return false;
    return true;
  };

  //get client token
  getClientToken = (): string | null => {
    const cToken = localStorage.getItem("authToken");
    return cToken;
  };

  //get staff token
  getStaffToken = (): string | null => {
    const sToken = localStorage.getItem("staffAuthToken");
    return sToken;
  };

  //clear the staff Token
  logOutStaff = () => {
    localStorage.setItem("staffAuthToken", "");
  };

  logOutClient = () => {
    localStorage.setItem("authToken", "");
    localStorage.setItem("clientName", "");
    localStorage.removeItem("branchLocation");
  };

  //get base url
  getBaseURL = () => {
    const API_URL = process.env.REACT_APP_BASE_URL;
    // const API_URL = "http://localhost:5000/api/";
    return API_URL;
  };

  getLMBaseURL = () => {
    const LM_API_URL = process.env.REACT_APP_LM_BASE_URL;
    return LM_API_URL;
  };

  LMLoginData = () => {
    return {
      username: process.env.REACT_APP_LM_USERNAME,
      password: process.env.REACT_APP_LM_PASSWORD,
    };
  };

  render() {
    return <div></div>;
  }
}
