/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import SessionData from "../../services/session-data";
import axios from "axios";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import PersonSharpIcon from "@material-ui/icons/PersonSharp";
import "../../styles/main-style.css";
import "react-tagsinput/react-tagsinput.css";
import Paper from "@material-ui/core/Paper";
import UpdateClientPassword from "./UpdateClientPassword";
import RegisterAdminUser from "./RegisterAdminUser";
import { Breadcrumbs, ListItemIcon, Typography } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import ModifyUserDetails from "./ModifyUserDetails";
import DeleteUserDetails from "./DeleteUserDetails";
import AppBarAndDrawer from "../Dashboard/AppBarAndDrawer";
import sub_menu from "../../images/sub_menu.png";
import { Link } from "react-router-dom";
import suspend_av from "../../images/suspend_autoclave.png";
import activate_av from "../../images/activate_autoclave.png";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

export type companyInfo = {
  name: string;
  email: string;
  client_id: string;
  id: number;
  flag: number;
};

export default function GetCompanyInfo() {
  const [client, setClient] = useState([]);
  const [clientName, setClientName] = useState<String | null>("");
  const [dialog, setDialog] = useState("hidden");
  const [open, setOpen] = React.useState(false);
  const [ask, setAsk] = useState(false);
  const [dMessage, setDMessage] = React.useState("");
  const [updatePass, setUpdatePass] = useState<boolean>(false);
  const [editDialog, setEditDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [callEffect, setCallEffect] = useState<boolean>(false);
  const [itemToModify, setItemToModify] = useState<any>([]);
  const [itemToDelete, setItemToDelete] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  // For the activate and deactivate of the user information
  const [activeStatus, setActiveStatus] = useState<number>();
  var [statusD, setStatusD] = useState("");
  var [id, setId] = useState("");
  const [userId, setUserId] = useState(0);

  let getData: companyInfo;

  // creating the session data variable
  const sd = new SessionData();
  useEffect(() => {
    getClientInfo();
  }, [callEffect]);
  // Getting the client Information

  const getClientInfo = () => {
    setDialog("hidden");
    axios
      .get(sd.getBaseURL() + "user/companyinfo", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + sd.getClientToken(),
        },
      })
      .then((data) => {
        if (data.status !== 200) {
          setDMessage("Not able to get Company info");
          setOpen(true);
          return;
        }
        setClientName(localStorage.getItem("clientName"));
        setClient(data.data);
      })
      .catch((err) => {
        setDMessage("Not able to get Company info");
        setOpen(true);
      });
  };

  // Changing the status of the user
  const handleUserStatus = () => {
    axios
      .put(
        sd.getBaseURL() + "user/status",
        {
          id: id,
          flag: statusD,
        },
        {
          method: "PUT",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("authToken"),
          },
        }
      )
      .then((data) => {
        if (data.status === 200) {
          setDMessage("Status Changed");
          getClientInfo();
          setLoading(false);
          setOpen(true);
        }
      })
      .catch((err) => {
        setDMessage("Error Changing Status");
        setLoading(false);
        setOpen(true);
      });
  };

  const handleClose = () => {
    setOpen(false);
    setCallEffect(!callEffect);
    setAsk(false);
  };

  const errorDialog = () => {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Update User</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" color="textPrimary">
            {dMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Done
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  // Question Dialouge
  const questionDialog = () => {
    return (
      <Dialog
        open={ask}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Attention</DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{ fontSize: "20px" }}
            id="alert-dialog-description"
          >
            {dMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            style={{ fontSize: "20px", color: "red" }}
            color="secondary"
            autoFocus
          >
            Cancel
          </Button>
          <Button
            style={{ fontSize: "20px" }}
            onClick={statusChangeCall}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  // Handling the status change call
  const statusChangeCall = () => {
    setAsk(false);
    handleUserStatus();
  };

  const handleMenu = (event, getItemData: companyInfo) => {
    setAnchorEl(event.currentTarget);
    setItemToModify(getItemData);
    setItemToDelete(getItemData);
    setActiveStatus(getItemData.flag);
    setUserId(getItemData.id);
  };

  // Modifying the item
  const modifyItem = () => {
    return (
      <ModifyUserDetails
        editOpen={true}
        data={itemToModify}
        parentCallback={handleModifyDialog}
      />
    );
  };
  // Deleting the item

  const deleteItem = () => {
    return (
      <DeleteUserDetails
        deleteOpen={true}
        data={itemToDelete}
        parentCallback={handleDeleteDialog}
      />
    );
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  // Modify Dialog handle
  const handleModifyDialog = (message: any) => {
    setDMessage(message);
    setOpen(true);
    setEditDialog(false);
    setAnchorEl(null);
  };
  // Delete Dialog handle
  const handleDeleteDialog = (message: any) => {
    setDMessage(message);
    setOpen(true);
    setDeleteDialog(false);
    setAnchorEl(null);
  };
  // Handling the edit click
  const handleTheClick = (e) => {
    setEditDialog(true);
    setAnchorEl(null);
  };

  // Handling the delete function
  const handleTheDelete = (e) => {
    setDeleteDialog(true);
    setAnchorEl(null);
  };

  // To change the flag of the user to activate and deactivate
  const onStatusChange = (e) => {
    setAnchorEl(null);
    client &&
      client.map((item, index) => {
        getData = item;
        if (getData.id == e) {
          if (getData.flag === 1) {
            setId(getData.id.toString());
            setStatusD("0");
            setDMessage("Are you sure you want to Deactivate the User?");
            setAsk(true);
          } else if (getData.flag === 0) {
            setId(getData.id.toString());
            setStatusD("1");
            setDMessage("Are you sure you want to Activate the User?");
            setAsk(true);
          }
        }
      });
  };
  return (
    <>
      <div>
        <AppBarAndDrawer />
        <div style={{ marginLeft: "260px" }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" to="/">
              Home
            </Link>
            <Typography color="textPrimary">Users Information</Typography>
          </Breadcrumbs>
          <div className="container text-center">
            <h2
              className="jFontBold"
              style={{ textAlign: "center", margin: "20px" }}
            >
              Users List ({clientName})
            </h2>
            <div className="row justify-content-md-center">
              <div className="col-lg-6 col-sm-12 text-center">
                <List>
                  <div
                    style={{
                      width: "auto",
                      margin: "0 auto",
                      textAlign: "center",
                    }}
                  >
                    <Paper>
                      {client &&
                        client?.map((item, index) => {
                          const getData: companyInfo = item;
                          return (
                            <>
                              <ListItem key={getData.id}>
                                <ListItemAvatar>
                                  <Avatar>
                                    <PersonSharpIcon />
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                  primary={getData.name}
                                  secondary={getData.email}
                                />
                                <Button
                                  id="basic-button"
                                  aria-controls={
                                    openMenu ? "basic-menu" : undefined
                                  }
                                  aria-haspopup="true"
                                  aria-expanded={openMenu ? "true" : undefined}
                                  onClick={(event) =>
                                    handleMenu(event, getData)
                                  }
                                >
                                  <img
                                    src={sub_menu}
                                    style={{ width: "30px", height: "30px" }}
                                    alt="sub-menu"
                                  />
                                </Button>
                              </ListItem>
                              {/* <div>{getData.name}</div>
                <div>{getData.email}</div>
                <div>{getData.client_id}</div> */}
                            </>
                          );
                        })}
                    </Paper>
                  </div>
                </List>
                <div className="row">
                  <div className="col-6">
                    <button
                      type="button"
                      value="update"
                      onClick={(e) => setUpdatePass(true)}
                      className="btn btn-danger mt-5"
                    >
                      Update Password
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      type="button"
                      value="register"
                      onClick={(e) => setUpdatePass(false)}
                      className="btn btnColor mt-5"
                    >
                      Register Admin User
                    </button>
                  </div>
                </div>

                {updatePass ? <UpdateClientPassword /> : <RegisterAdminUser />}
                {/* {updatePass ? <RegisterAdminUser /> : ''} */}
              </div>
            </div>
          </div>
        </div>
        {/* // setUpdatePass(prev => !prev)} */}
        {open === true ? errorDialog() : ""}
      </div>
      <div>{editDialog === true ? modifyItem() : ""}</div>
      <div>{deleteDialog === true ? deleteItem() : ""}</div>
      <div> {ask === true ? questionDialog() : ""}</div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={(e) => handleTheClick(e)}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText>
            <div style={{ fontSize: "16px", marginLeft: "10px" }}>Edit</div>
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={(e) => handleTheDelete(e)}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText defaultValue="delete">
            <div style={{ fontSize: "16px", marginLeft: "10px" }}>Delete</div>
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={() => onStatusChange(userId)}>
          <ListItemIcon>
            {activeStatus === 0 ? (
              <img src={activate_av} width={25} alt="activate" />
            ) : (
              <img src={suspend_av} width={25} alt="suspend" />
            )}
          </ListItemIcon>
          <ListItemText>
            <div style={{ fontSize: "16px", marginLeft: "10px" }}>
              {activeStatus === 0 ? "Activate" : "Deactivate"}
            </div>
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}
