import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Brightness1Icon from "@material-ui/icons/Brightness1";
import Button from "@material-ui/core/Button";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 10,
    },
    paper: {
      padding: theme.spacing(2),
      // textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    chip: {
      margin: theme.spacing(0.5),
      fontSize: "16px",
    },
    section1: {
      margin: theme.spacing(1, 2, 1, 2),
    },
    section2: {
      margin: theme.spacing(0.5, 2, 0.5, 2),
    },
    section3: {
      margin: theme.spacing(3, 1, 1),
    },
  })
);

export default function AutoclaveLoadListView() {
  const classes = useStyles();

  const listItemDesign = () => {
    return (
      <>
        <Container maxWidth="md" style={{ marginTop: "10px" }}>
          <Paper className={classes.paper} style={{ borderRadius: "20px" }}>
            <Grid
              container
              style={{ fontFamily: "montserrat", display: "flex" }}
            >
              <Grid item sm={4} style={{ flex: "1" }}>
                <div className="dFlexColLeft">
                  <div className="cAvName">BRAVO 12 V</div>
                  <div className="cAvDate">2021-07-02</div>
                  <div className="cAvStatus">FINISHED</div>
                </div>
              </Grid>
              <Grid item sm={4} style={{ flex: "1" }}>
                <div className="dFlexColLeft">
                  <Button
                    className="cButton"
                    variant="contained"
                    color="primary"
                  >
                    view items
                  </Button>
                  <div className="dFlexRow">
                    <div>
                      C5{" "}
                      <span>
                        <Brightness1Icon className="roundIcon" />
                      </span>
                    </div>
                    <div>
                      BI{" "}
                      <span>
                        <Brightness1Icon className="roundIcon" />
                      </span>
                    </div>
                    <div>
                      C2{" "}
                      <span>
                        <Brightness1Icon className="roundIcon" />
                      </span>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item sm={4} style={{ flex: "1" }}>
                <div className="dFlexColRight">
                  <p className="cAvCycle">235</p>
                  <div className="cAvStatus">
                    BY - <span>JAGTAR</span>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </>
    );
  };
  return <div>{listItemDesign()}</div>;
}
