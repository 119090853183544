import React, { useState } from "react";
import SessionData from "../../services/session-data";
import axios from "axios";
import Paper from "@material-ui/core/Paper";
import { RepeatRounded } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import validator from "validator";

export default function RegisterAdminUser() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPass, setRepeatPass] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dialog, setDialog] = useState("hidden");
  const [open, setOpen] = React.useState(false);
  const [dMessage, setDMessage] = React.useState("");
  const [passmsg, setPassMsg] = useState("");
  const [sendEmail, setSendEmail] = useState(false);

  const checkPassword = (value) => {
    setRepeatPass(value);
    if (value !== password) setPassMsg("The passwords don't match");
    else setPassMsg("");
  };

  const formValidation = () => {
    //everything is good
    if (
      name.length > 0 &&
      validator.isEmail(email) &&
      password.length >= 6 &&
      password === repeatPass
    ) {
      //make a call
      registerUser();
    }
    //fields empty
    else if (
      email.length <= 0 ||
      password.length <= 0 ||
      RepeatRounded.length <= 0 ||
      name.length <= 0
    ) {
      setDMessage("Please fill all the fields");
      setOpen(true);
      return;
    } //password length
    else if (password.length < 6 || password.length > 30) {
      setDMessage("Password must be atlease 6 characters long");
      setOpen(true);
      return;
    } //email not valid
    else if (!validator.isEmail(email)) {
      setDMessage("Email id is not valid");
      setOpen(true);
      return;
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const errorDialog = () => {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Register New User</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const registerUser = () => {
    const sd = new SessionData();
    setDialog("hidden");

    axios
      .post(
        sd.getBaseURL() + "user",
        {
          name: name,
          email: email,
          password: password,
          sendEmail: sendEmail,
        },
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + sd.getClientToken(),
          },
        }
      )
      .then((data) => {
        if (data.status !== 201) {
          setDMessage("Registration Failed. Please check your fields!");
          setOpen(true);
          return;
        }
        setDMessage("New User Registered");
        setOpen(true);
      })
      .catch((err) => {
        setDMessage("Registration Failed. Please check your fields");
        setOpen(true);
      });
    //empty everything
    setEmail("");
    setName("");
    setPassword("");
    setRepeatPass("");
  };

  return (
    <>
      <div className="container jMarginTop50 jMarginBottom50">
        <h2
          className="jFontBold"
          style={{ textAlign: "center", margin: "20px" }}
        >
          Register User
        </h2>
        <Paper className="jPadding">
          <form>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                className="form-control"
                required
                onChange={(e) => setName(e.target.value)}
                value={name}
                id="name"
              ></input>
            </div>

            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                className="form-control"
                required
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                id="email"
              ></input>
            </div>

            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                type="text"
                className="form-control"
                required
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                id="password"
              ></input>
            </div>

            <div className="form-group">
              <label htmlFor="repeatPassword">Repeat Password</label>
              <input
                type="text"
                className="form-control"
                required
                onChange={(e) => checkPassword(e.target.value)}
                value={repeatPass}
                id="repeatPassword"
              ></input>
              <div style={{ color: "red" }}>{passmsg}</div>
            </div>

            <div className="row">
              <div className="col-4">
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    value=""
                    id="sendEmail"
                    onChange={() => setSendEmail((prev) => !prev)}
                  ></input>
                  <label className="form-check-label" htmlFor="sendEmail">
                    Send Email
                  </label>
                </div>
              </div>
            </div>
            <button
              type="button"
              onClick={formValidation}
              className="btn btnColor btn-lg"
            >
              Register
            </button>
          </form>
          <div className="row">
            {/* <div className="col" >
                    <div style={myStyles} >Please fill autoclave name, cycle no, and bowie dick to register</div>
                </div> */}
          </div>
        </Paper>
      </div>
      {open === true ? errorDialog() : ""}
    </>
  );
}
