/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import SessionData from "../../../services/session-data";
import axios from "axios";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import LocationCityRoundedIcon from "@material-ui/icons/LocationCityRounded";
import { Link } from "react-router-dom";
import "../../../styles/main-style.css";
import "react-tagsinput/react-tagsinput.css";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import getBranchList from "../../Utils/FetchBranchList";
import { Breadcrumbs, Typography } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ModifyBranch from "./ModifyBranch";
import DeleteBranch from "./DeleteBranch";
import AppBarAndDrawer from "../../Dashboard/AppBarAndDrawer";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import sub_menu from "../../../images/sub_menu.png";
import suspend_av from "../../../images/suspend_autoclave.png";
import activate_av from "../../../images/activate_autoclave.png";

export type branchLocations = {
  branch_id: number;
  branch_name: string;
  city: string;
  branch_contact: number;
  flag: number;
};

export default function RegisterBranch() {
  const [branchName, setBranchName] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [contact, setContact] = useState<number | null>(null);
  const [dialog, setDialog] = useState("hidden");
  const [open, setOpen] = React.useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [dMessage, setDMessage] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [callEffect, setCallEffect] = useState<boolean>(false);
  const [itemToModify, setItemToModify] = useState<any>([]);
  const [itemToDelete, setItemToDelete] = useState<any>([]);
  // For the activate and deactivate of the user information
  const [activeStatus, setActiveStatus] = useState<number>();
  var [statusD, setStatusD] = useState("");
  var [id, setId] = useState("");
  const [branchId, setBranchId] = useState(0);
  const [ask, setAsk] = useState(false);
  const [loading, setLoading] = useState(false);

  const getBranches = getBranchList();
  let getData: branchLocations;

  useEffect(() => {
    getBranches.request();
  }, [callEffect]);

  const handleClose = () => {
    setOpen(false);
    setCallEffect(!callEffect);
    setAsk(false);
  };

  //check if value is provided by user
  const formValidation = () => {
    if (branchName.length <= 0 || city.length <= 0 || contact === null) {
      setDMessage("Please provide required Information");
      setOpen(true);
      return;
    }
    RegisterNewBranch();
  };
  // To register new branch
  const sd = new SessionData();
  const RegisterNewBranch = () => {
    setDialog("hidden");
    axios
      .post(
        sd.getBaseURL() + "web-register/newbranch",
        {
          branch_name: branchName,
          city: city,
          contact: contact,
        },
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + sd.getClientToken(),
            "staff-auth": "Bearer " + sd.getStaffToken(),
          },
        }
      )
      .then((data) => {
        if (data.status !== 201) {
          setDMessage("Not able to Register Branch");
          setOpen(true);
        }
        setDMessage(branchName + " Branch Registered!!");
        setOpen(true);
      })
      .catch(() => {
        setDMessage("Not able to Register Branch");
        setOpen(true);
      });
    //empty everything
    setBranchName("");
    setCity("");
    setContact(null);
  };
  // To change the status of the branch
  const handleBranchStatus = () => {
    axios
      .put(
        sd.getBaseURL() + "web-register/status",
        {
          branch_id: branchId,
          flag: statusD,
        },
        {
          method: "PUT",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("authToken"),
          },
        }
      )
      .then((data) => {
        if (data.status === 200) {
          setDMessage("Status Changed");
          setLoading(false);
          setOpen(true);
        }
      })
      .catch(() => {
        setDMessage("Error Changing Status");
        setLoading(false);
        setOpen(true);
      });
  };

  // Error Dialog Box
  const errorDialog = () => {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Branch Information</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" color="textPrimary">
            {dMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Done
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  // Question Dialouge
  const questionDialog = () => {
    return (
      <Dialog
        open={ask}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Attention</DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{ fontSize: "20px" }}
            id="alert-dialog-description"
          >
            {dMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            style={{ fontSize: "20px", color: "red" }}
            color="secondary"
            autoFocus
          >
            Cancel
          </Button>
          <Button
            style={{ fontSize: "20px" }}
            onClick={statusChangeCall}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  // Handling the status change call
  const statusChangeCall = () => {
    setAsk(false);
    handleBranchStatus();
  };
  const handleMenu = (event, getItemData: branchLocations) => {
    setAnchorEl(event.currentTarget);
    setItemToModify(getItemData);
    setItemToDelete(getItemData);
    setActiveStatus(getItemData.flag);
    setBranchId(getItemData.branch_id);
  };

  const modifyItem = () => {
    return (
      <ModifyBranch
        editOpen={true}
        data={itemToModify}
        parentCallback={handleModifyDialog}
      />
    );
  };

  //to delete the item
  const deleteItem = () => {
    return (
      <DeleteBranch
        deleteOpen={true}
        data={itemToDelete}
        parentCallback={handleDeleteDialog}
      />
    );
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  // Modify Dialog handle
  const handleModifyDialog = (message: any) => {
    setDMessage(message);
    setOpen(true);
    setEditDialog(false);
    setAnchorEl(null);
  };

  //Delete Dialog handle
  const handleDeleteDialog = (message: any) => {
    setDMessage(message);
    setOpen(true);
    setDeleteDialog(false);
    setAnchorEl(null);
  };

  const handleEditClick = (e) => {
    setEditDialog(true);
    setAnchorEl(null);
  };

  //To delete the use
  const handleDeleteClick = (e) => {
    setDeleteDialog(true);
    setAnchorEl(null);
  };
  // To change the flag of the branch to activate and deactivate
  const onStatusChange = (e) => {
    setAnchorEl(null);
    getBranches.data &&
      getBranches.data.map((item, index) => {
        getData = item;
        if (getData.branch_id == e) {
          if (getData.flag === 1) {
            setId(getData.branch_id.toString());
            setStatusD("0");
            setDMessage("Are you sure you want to Deactivate the User?");
            setAsk(true);
          } else if (getData.flag === 0) {
            setId(getData.branch_id.toString());
            setStatusD("1");
            setDMessage("Are you sure you want to Activate the User?");
            setAsk(true);
          }
        }
      });
  };
  return (
    <>
      <AppBarAndDrawer />
      <div style={{ marginLeft: "260px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" to="/">
            Home
          </Link>
          <Typography color="textPrimary">Register Branch</Typography>
        </Breadcrumbs>
        <div
          className="container dFlex dFlexCol text-center"
          style={{
            width: "80%",
            justifyContent: "center",
            margin: "auto",
          }}
        >
          {/* {editMenu.createEditMenu()} */}
          <div className="row justify-content-md-center">
            <div className="col-lg-6 col-sm-12 text-center">
              <h2 className="text-center jFontBold mt-3 mb-2">
                Add New Branch
              </h2>
              <Paper className="jPadding box-shadow">
                <form>
                  <div className="form-group">
                    <label htmlFor="brnachName">Branch Name</label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      onChange={(e) => setBranchName(e.target.value)}
                      value={branchName}
                      id="brnachName"
                    ></input>
                  </div>
                  <div className="form-group">
                    <label htmlFor="city">City</label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      onChange={(e) => setCity(e.target.value)}
                      value={city}
                      id="city"
                    ></input>
                  </div>
                  <div className="form-group">
                    <label htmlFor="contact">Contact</label>
                    <input
                      type="number"
                      className="form-control"
                      required
                      onChange={(e) => setContact(Number(e.target.value))}
                      value={Number(contact).toString()}
                      id="contact"
                    ></input>
                  </div>
                  <button
                    type="button"
                    onClick={formValidation}
                    className="btn btnColor btn-lg"
                  >
                    Submit
                  </button>
                </form>
              </Paper>
            </div>
          </div>
          <div className="row justify-content-md-center">
            <div className="col-lg-6 col-sm-12 text-center">
              <h2 className="text-center jFontBold mt-3 mb-2">
                Registered Branches
              </h2>
              <List>
                <div
                  style={{
                    width: "auto",
                    margin: "0 auto",
                    textAlign: "center",
                  }}
                >
                  <Paper>
                    {getBranches.loading && <p>Branches are loading!</p>}
                    {getBranches.data &&
                      getBranches.data?.map((item, index) => {
                        const getData: branchLocations = item;
                        return (
                          <ListItem key={getData.branch_id}>
                            <ListItemAvatar>
                              <Avatar>
                                <LocationCityRoundedIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={getData.branch_name}
                              secondary={
                                getData.city + "-" + getData.branch_contact
                              }
                            />
                            <Button
                              id="basic-button"
                              aria-controls={
                                openMenu ? "basic-menu" : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={openMenu ? "true" : undefined}
                              onClick={(event) => handleMenu(event, getData)}
                            >
                              <img
                                src={sub_menu}
                                style={{ width: "30px", height: "30px" }}
                                alt="sub-menus"
                              />
                            </Button>
                          </ListItem>
                        );
                      })}
                  </Paper>
                </div>
              </List>
            </div>
          </div>
        </div>
        {open === true ? errorDialog() : ""}
      </div>
      <div>{editDialog === true ? modifyItem() : ""}</div>
      <div>{deleteDialog === true ? deleteItem() : ""}</div>
      <div> {ask === true ? questionDialog() : ""}</div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={(e) => handleEditClick(e)}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText>
            <div style={{ fontSize: "16px", marginLeft: "10px" }}>Edit</div>
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={(e) => handleDeleteClick(e)}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText defaultValue="delete">
            <div style={{ fontSize: "16px", marginLeft: "10px" }}>Delete</div>
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={() => onStatusChange(branchId)}>
          <ListItemIcon>
            {activeStatus === 0 ? (
              <img src={activate_av} width={25} alt="activate" />
            ) : (
              <img src={suspend_av} width={25} alt="suspend" />
            )}
          </ListItemIcon>
          <ListItemText>
            <div style={{ fontSize: "16px", marginLeft: "10px" }}>
              {activeStatus === 0 ? "Activate" : "Deactivate"}
            </div>
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}
