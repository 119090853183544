/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-lone-blocks */
import { useState, useEffect } from "react";
import axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { readersType } from "./RegisterNewReader";
import SessionData from "../../../services/session-data";
import SelectBranch from "../../Utils/SelectBranch";

export default function ModifyBranch(props: any) {
  const [open, setOpen] = useState(false);
  const [readerName, setReaderName] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [branch, setBranch] = useState<string>("");
  let data: readersType = props.data;
  const [error, setError] = useState(false);
  let message = "";

  const handleClose = () => {
    setOpen(false);
    props.parentCallback(message);
  };

  useEffect(() => {
    {
      setReaderName(data.reader);
      setLocation(data.location);
      setBranch(data.branch_name);
      props.editOpen ? setOpen(true) : setOpen(false);
    }
  }, []);

  const EditReader = async () => {
    const sd = new SessionData();
    try {
      const res = await axios.put(
        sd.getBaseURL() + "web-register/newreader",
        {
          loc_id: data.loc_id,
          reader: readerName,
          location: location,
          branch_id: localStorage.getItem("branchLocation"),
        },
        {
          method: "PUT",
          headers: {
            Authorization: "Bearer " + sd.getClientToken(),
            "staff-auth": "Bearer " + sd.getStaffToken(),
          },
        }
      );
      if (res.status === 201) {
        message = "Branch Updated";
      }
    } catch (err: any) {
      message = "Something Went Wrong";
    } finally {
      setReaderName("");
      setLocation("");
      setBranch("");
      handleClose();
    }
  };

  const showTheDialog = () => {
    return (
      <div>
        <Dialog
          fullWidth={true}
          maxWidth="md"
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">Edit Reader</DialogTitle>
          <DialogContent style={{ backgroundColor: "#eff9f0" }}>
            <DialogContentText>
              {/* showing articles in this load */}
              <form>
                <div className="form-group">
                  <label htmlFor="readerName">Reader Name</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) => setReaderName(e.target.value)}
                    value={readerName}
                    id="readerName"
                  ></input>
                </div>
                <div className="form-group">
                  <label htmlFor="location">Reader Location</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) => setLocation(e.target.value)}
                    value={location}
                    id="location"
                  ></input>
                </div>
                <div className="form-group">
                  <label htmlFor="branchName">Branch Name</label>
                  <SelectBranch />
                </div>
                {error ? <div>Please fill all the fields</div> : ""}
              </form>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ backgroundColor: "#eff9f0" }}>
            <Button onClick={formValidation} color="primary" autoFocus>
              Submit
            </Button>
            <Button onClick={formValidation} style={{ color: "red" }}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const formValidation = () => {
    // if () {
    //   setError(true);
    //   return;
    // }
    EditReader();
  };

  return <div>{open ? showTheDialog() : ""}</div>;
}
