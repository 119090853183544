/* eslint-disable no-lone-blocks */
import React, { useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import GetArticlesPerLoad from "../Articles/ArticlesPerLoad/ArticlesPerLoad";
import Button from "@material-ui/core/Button";

export default function AutoclaveInfoDialog(props: any) {
  /*React Dialog*/
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    props.parentCallback(false);
  };

  useEffect(() => {
    {
      props.open ? setOpen(true) : setOpen(false);
    }
  }, []);

  const showTheDialog = () => {
    return (
      <div>
        <Dialog
          fullWidth={true}
          maxWidth="md"
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {props.avInfo.Autoclave}
          </DialogTitle>
          <DialogContent style={{ backgroundColor: "#eff9f0" }}>
            <DialogContentText>
              {/* showing articles in this load */}
              <GetArticlesPerLoad id={props.id} info={props.avInfo} />
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ backgroundColor: "#eff9f0" }}>
            <Button onClick={handleClose} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  return <div>{open ? showTheDialog() : ""}</div>;
}
