/* eslint-disable */
import React, { Component, useState, useEffect } from "react";
import validator from "validator";
import { Redirect, useHistory, Link } from "react-router-dom";
import AuthService from "../../services/auth-service";
import axios from "axios";
import SessionData from "../../services/session-data";
import logoImage from "../../images/reprolog_logo.png";

export default function ResetPassword() {
  const sd = new SessionData();
  const API_URL = sd.getBaseURL();

  //client login response type
  type resetPassword = {
    email: string;
    secret: string;
    newPassword: string;
    cid: string;
  };

  const [clientid, setClientid] = useState<any>("");
  const [username, setUsername] = useState("");
  const [secret, setSecret] = useState("");
  const [password, setPassword] = useState("");
  const [query, setQuery] = useState("");
  const [login, setLogin] = useState("");
  const [showInfo, setShowInfo] = useState(false);
  const [message, setMessage] = useState("");
  let history = useHistory();
  var passwordResetData: resetPassword;

  useEffect(() => {
    if (localStorage.getItem("clientName") != null)
      setClientid(localStorage.getItem("clientName"));
  }, []);

  const validateFields = (e) => {
    e.preventDefault();
    if (
      clientid.length > 0 &&
      username.length > 0 &&
      validator.isEmail(username) &&
      secret.length > 0
    ) {
      if (password.length >= 8) {
        //update object
        passwordResetData = {
          email: username,
          secret: secret,
          newPassword: password,
          cid: `DENTAL_${clientid.toUpperCase()}`,
        };
        resetPassword();
      } else {
        setMessage("Password length must be atleast 8 characters");
        setShowInfo(true);
      }
    } else {
      setMessage("Please fill all the fields");
      setShowInfo(true);
    }
  };

  //Reset the Password
  const resetPassword = () => {
    axios
      .put(API_URL + "user/reset-password", passwordResetData, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((data) => {
        if (data.status == 200) {
          setMessage("Your password has been updated");
          setShowInfo(true);
        } else if (data.status == 400) {
          setMessage("Request Failed. Please check your credentials");
          setShowInfo(true);
        }
      })
      .catch((error) => {
        setMessage(error.response.data.errorDetails);
        setShowInfo(true);
      });
  };

  return (
    <div className="container-fluid login-bg">
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex flex-column min-vh-100  align-items-center ">
            <div className="main_logo_client">
              {/* <img src={logoImage} /> */}
              <div className="cloginTitle">
                <h1>Reset Password</h1>
              </div>
            </div>
            <div className="clientLogin">
              {login ? "Login Successfull" : ""}
              <form>
                <div className="form-group">
                  <label htmlFor="inputClientId">Client id</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setClientid(e.target.value)}
                    value={clientid}
                    id="inputClientId"
                    placeholder="client id"
                  ></input>
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Email address</label>
                  <input
                    type="email"
                    className="form-control"
                    onChange={(e) => setUsername(e.target.value)}
                    value={username}
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter email"
                  ></input>
                </div>
                <div className="form-group">
                  <label htmlFor="inputResetCode">Reset Code</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setSecret(e.target.value)}
                    value={secret}
                    id="inputResetCode"
                    placeholder=""
                  ></input>
                </div>
                <div className="form-group">
                  <label htmlFor="inputPasssword">Password</label>
                  <input
                    type="password"
                    className="form-control"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    id="inputPasssword"
                    placeholder=""
                  ></input>
                </div>
                <button
                  type="submit"
                  onClick={(e) => validateFields(e)}
                  className="btn btn-danger btn-lg btn-block"
                >
                  Update Password
                </button>

                <p style={{ marginTop: "20px", width: "200px" }}>
                  <Link to="/main-login">Login?</Link>{" "}
                </p>
                {showInfo ? (
                  <p style={{ textAlign: "center" }}>{message}</p>
                ) : (
                  ""
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
