/* eslint-disable */
import React, {useState, useEffect} from 'react'
import {Bar} from 'react-chartjs-2'
import '../../styles/main-style.css'
import { BrowserRouter as Router, Link, Switch, Route, Redirect, withRouter } from 'react-router-dom'
import {useStyles} from '../Styles/mui-styles'
import Header from '../NavBar/header';
    import {
      MuiPickersUtilsProvider,
      KeyboardTimePicker,
      KeyboardDatePicker,
    } from '@material-ui/pickers';
    import DateFnsUtils from '@date-io/date-fns'
    import Grid from '@material-ui/core/Grid';
    import SessionData from '../../services/session-data'
    import axios from 'axios';
    import CancelIcon from '@material-ui/icons/Cancel';

export default function dateSelector(props: any) {

    const [currentURL, setCurrentURL] = useState("")
    const [selectedDate, setSelectedDate] = useState<Date | String | null>(null);

    const handleDateChange = (date:Date | null) => {
        if(date !== null && String(date) !== '') {
            const dateFormat = date.getFullYear() + "-" + (date.getMonth() + 1 ) + "-" + date.getDate()
            setSelectedDate(dateFormat);
            setCurrentURL(`load_started_at=${dateFormat}`)
            props.parentCallback(dateFormat)
            }else{
                setSelectedDate(null)
                props.parentCallback("")
            }
           
        }

    const dateSelector = () => {
        return(
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justify="space-around">
            <KeyboardDatePicker
                disableToolbar
                autoOk={true}
                variant="inline"
                label= "DATE"
                format="yyyy-MM-dd"
                id="date-picker-inline"
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                'aria-label': 'change date',
                }}/>
            </Grid>
        </MuiPickersUtilsProvider>
        )
    }
    return (
        <div className="row justify-content-md-center">
            <div className="col-md-7">  {dateSelector()}
         {selectedDate != null ?      <div style={{position: 'absolute', right: '0', top: '0', marginTop: '8px'}}>
                    <CancelIcon onClick={() => handleDateChange(null)} style={{color: '#e76f51',width: '20px', marginTop: '12px'}} />
                </div>  : '' }
                </div>
        </div>
    )
}
