/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import SessionData from "../../../services/session-data";
import axios from "axios";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import "../../../styles/main-style.css";
import "react-tagsinput/react-tagsinput.css";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import PersonSharpIcon from "@material-ui/icons/PersonSharp";
import getEmailList from "../../Utils/FetchEmailList";
import AppBarAndDrawer from "../../Dashboard/AppBarAndDrawer";
import { Breadcrumbs, Typography } from "@material-ui/core";

export type registeredEmails = {
  id: number;
  name: string;
  email_id: string;
};

export default function RegisterEmail() {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [dialog, setDialog] = useState("hidden");
  const [open, setOpen] = React.useState(false);
  const [dMessage, setDMessage] = React.useState("");
  const getEmail = getEmailList();
  const [callEffect, setCallEffect] = useState<boolean>(false);

  useEffect(() => {
    getEmail.request();
  }, [callEffect]);

  const handleClose = () => {
    setOpen(false);
    setCallEffect(!callEffect);
  };

  //check if value is provided by user
  const formValidation = () => {
    if (name.length <= 0 || email.length <= 0) {
      setDMessage("Please provide name and email id");
      setOpen(true);
      return;
    }
    RegisterNewEmail();
  };

  const RegisterNewEmail = () => {
    const sd = new SessionData();
    setDialog("hidden");

    axios
      .post(
        sd.getBaseURL() + "web-register/addemails",
        {
          name: name,
          email: email,
        },
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + sd.getClientToken(),
            "staff-auth": "Bearer " + sd.getStaffToken(),
          },
        }
      )
      .then((data) => {
        if (data.status !== 201) {
          setDMessage("Not able to Insert email");
          setOpen(true);
        }
        setDMessage("New Email Added");
        setOpen(true);
      })
      .catch((err) => {
        setDMessage("Not able to Insert email");
        setOpen(true);
      });
    //empty everything
    setName("");
    setEmail("");
  };

  const errorDialog = () => {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Add Email</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <AppBarAndDrawer />
      <div style={{ marginLeft: "260px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" to="/">
            Home
          </Link>
          <Typography color="textPrimary">Register Emails</Typography>
        </Breadcrumbs>

        <div
          className="container dFlex dFlexCol"
          style={{ width: "80%", height: "80%", justifyContent: "center" }}
        >
          <div className="row justify-content-md-center">
            <div className="col-lg-6 col-sm-12 text-center">
              <h2 className="text-center jFontBold">Add New Email id</h2>
              <Paper className="jPadding box-shadow">
                <form>
                  <div className="form-group">
                    <label htmlFor="autoclaveName">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                      id="autoclaveName"
                    ></input>
                  </div>
                  <div className="form-group">
                    <label htmlFor="autoclaveName">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      required
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      id="autoclaveName"
                    ></input>
                  </div>

                  <button
                    type="button"
                    onClick={formValidation}
                    className="btn btnColor btn-lg"
                  >
                    Submit
                  </button>
                </form>
                <div className="row"></div>
              </Paper>
            </div>
          </div>
        </div>
        <div className="row justify-content-md-center">
          <div className="col-lg-6 col-sm-12 text-center">
            <h2 className="text-center jFontBold mt-3 mb-2">
              Registered Emails
            </h2>
            <List>
              <div
                style={{ width: "auto", margin: "0 auto", textAlign: "center" }}
              >
                <Paper>
                  {getEmail.loading && <p>Emails are loading!</p>}
                  {getEmail.data &&
                    getEmail.data?.map((item, index) => {
                      const getData: registeredEmails = item;
                      return (
                        <ListItem key={getData.id}>
                          <ListItemAvatar>
                            <Avatar>
                              <PersonSharpIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={getData.name}
                            secondary={getData.email_id}
                          />
                        </ListItem>
                      );
                    })}
                </Paper>
              </div>
            </List>
          </div>
        </div>

        {open === true ? errorDialog() : ""}
      </div>
    </>
  );
}
