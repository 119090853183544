import React, { useState, useEffect } from "react";
import axios from "axios";
import SessionData from "../../../services/session-data";
import { Breadcrumbs, Paper, Typography } from "@material-ui/core";
import { useStyles } from "../../Styles/mui-styles";
import Chip from "@material-ui/core/Chip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Loader from "react-loader-spinner";
import Button from "@material-ui/core/Button";
import SelectBranch from "../../Utils/SelectBranch";
import AppBarAndDrawer from "../../Dashboard/AppBarAndDrawer";
import DeleteIcon from "@material-ui/icons/Delete";
import ListIcon from "@material-ui/icons/List";
import { Link } from "react-router-dom";
export type articleListType = {
  id: number;
  type: string;
  code: string;
  manufacturer: string;
};

export type articleKitType = {
  ak_id: number;
  ak_name: string;
  ak_lot: string;
  ak_tag_id: string;
  ak_tag_name: string;
  ak_tray_id: string;
  ak_tray_name: string;
  ak_user_id: number;
  branch_id: number;
  ak_comments: string;
  ak_type: string;
  ak_code: string;
  ak_manufacturer: string;
};
const sd = new SessionData();

export default function RegisterArticle() {
  let listData: articleListType;
  let kitData: articleKitType;
  const [articleTypesList, setArticleTypesList] = useState([]);
  const [type, setType] = useState("");
  const [code, setCode] = useState("");
  const [manufacturer, setManufacturer] = useState("");
  const [category, setCategory] = useState("");
  const [isActive, setIsActive] = useState("1");
  const [newTag, setNewTag] = useState("");
  const [valueStore, setValueStore] = useState<any>([]);
  const [currentKey, setCurrentKey] = useState("");
  const [newTray, setNewTray] = useState("");
  const [trayValueStore, setTrayValueStore] = useState<any>([]);
  const [trayCurrentKey, setTrayCurrentKey] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [dMessage, setDMessage] = React.useState("");
  const [loading, setLoading] = useState(false);
  const [lot, setLot] = useState("");
  const [articleIds, setArticleIds] = useState([]);
  const [alreadyRegistered, setAlreadyRegistered] = useState<any>([]);
  const [trayAlreadyRegistered, setTrayAlreadyRegistered] = useState<any>([]);
  const [callAPI, setCallAPI] = useState<boolean>(true);
  const [canReset, setCanReset] = useState<boolean>(false);
  const [articleKit, setArticleKit] = useState("");
  const [trayName, setTrayName] = useState("");
  const [buttonName, setButtonName] = useState("Register Article Set");
  //   Dynamic fields for the articles/instruments
  const [inputFields, setInputFields] = useState<any>([
    { articleName: [], articleTag: [] },
  ]);
  const [allArticleKit, setAllArticleKit] = useState<any>([]);
  const [hasTagData, setHasTagData] = useState(false);
  const [tagArray, setTagArray] = useState<any>([
    { articleName: [], articleTag: [] },
  ]);
  const [result, setResult] = useState<any>([]);

  const handleClose = () => {
    setOpen(false);
    if (canReset) {
      resetFields();
      setCanReset(false);
    }
  };

  useEffect(() => {
    getArticleTypes();
    getArticleIds();
    getArticleKits();
  }, [callAPI]);

  //Fetch All Article Types from API
  const getArticleTypes = () => {
    axios
      .get(sd.getBaseURL() + "web-standalone/get-article-types", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sd.getClientToken(),
        },
      })
      .then((data) => {
        if (data.status !== 200) throw new Error("List empty");
        setArticleTypesList(data.data);
      })
      .catch((err) => {});
  };

  // Fetch all the article kits

  const getArticleKits = () => {
    axios
      .get(sd.getBaseURL() + "web-standalone/get-all-article-kits", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sd.getClientToken(),
        },
      })
      .then((data) => {
        if (data.status !== 200) throw new Error("List Empty");
        setAllArticleKit(data.data);
      })
      .catch((err) => {});
  };

  let articleName: string[] = [];
  let articleTag: string[] = [];
  inputFields.forEach((item, indexes) => {
    articleName.push(item["articleName"]);
    articleTag.push(item["articleTag"]);
  });
  //Register New Article Set Request
  const registerNewArticle = () => {
    axios
      .post(
        sd.getBaseURL() + "web-register/registerarticlekit",
        {
          articleKit: articleKit,
          articleName: articleName,
          articleTag: articleTag,
          article_type: type,
          tray_id: newTray,
          // tray_id: trayValueStore,
          lot: lot,
          active: isActive,
          additional_info: additionalInfo,
          branch_id: localStorage.getItem("branchLocation"),
          user_id: "2",
          reader_loc_id: "2",
          trayName: trayName,
          type: type,
          code: code,
          manufacturer: manufacturer,
        },
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + sd.getClientToken(),
            "staff-auth": "Bearer " + sd.getStaffToken(),
          },
        }
      )
      .then((data) => {
        if (data.status !== 201) {
          setDMessage("Article Kit Registration Failed");
          setLoading(false);
          setOpen(true);
          throw new Error("not able to register the article kit");
        }
        setDMessage("New Article Kit Registered!");
        setCanReset(true);
        setLoading(false);
        setOpen(true);
      })
      .catch((err) => {
        setLoading(false);
        setDMessage("Article Kit Registration Failed");
        setOpen(true);
      });
  };
  //----------------------------
  const resetFields = () => {
    setArticleTypesList([]);
    setTrayName("");
    setArticleKit("");
    setType("");
    setCode("");
    setManufacturer("");
    setCategory("");
    setIsActive("1");
    setNewTag("");
    setValueStore([]);
    setCurrentKey("");
    setNewTray("");
    setTrayValueStore([]);
    setTrayCurrentKey("");
    setAdditionalInfo("");
    setLot("");
    setArticleIds([]);
    setAlreadyRegistered([]);
    setCallAPI(!callAPI);
    setInputFields([{ articleName: "", articleTag: "" }]);
    setAllArticleKit("");
    setHasTagData(!hasTagData);
  };
  //----------------------------
  //Storing AticleType Data on User Selection
  const setArticleTypeParams = (avId: string) => {
    if (avId !== "emptyOption") {
      articleTypesList &&
        articleTypesList.map((item, index) => {
          listData = item;

          if (listData.id.toString() === avId) {
            setType(listData.type);
            setCode(listData.code != null ? listData.code : "");
            setManufacturer(
              listData.manufacturer != null ? listData.manufacturer : ""
            );
            return;
          }
        });
    } else {
      setType("");
      setCode("");
      setManufacturer("");
    }
  };
  //Storing AticleType Data on User Selection
  // Selecting the existed article kits for the user

  const setArticleKitParams = (avId: string) => {
    if (avId !== "emptyOption") {
      allArticleKit &&
        allArticleKit.map((item, index) => {
          kitData = item;
          if (kitData.ak_id.toString() === avId) {
            setArticleKit(kitData.ak_name);
            setTrayName(kitData.ak_tray_name);
            setLot(kitData.ak_lot);
            setButtonName("Update Article Set");
            setTrayCurrentKey(kitData.ak_tray_id);
            setAdditionalInfo(kitData.ak_comments);
            setNewTray(kitData.ak_tray_id);
            setType(kitData.ak_type);
            setCode(kitData.ak_code != null ? kitData.ak_code : "");
            setManufacturer(
              kitData.ak_manufacturer != null ? kitData.ak_manufacturer : ""
            );
            setInputFields([
              {
                articleTag: kitData.ak_tag_id.split(","),
                articleName: kitData.ak_tag_name.split(","),
              },
            ]);
            // Creating array for the tag id and tag name as key value pair
            var articleTagArray: any = kitData.ak_tag_id.split(",");
            var articleNameArray: any = kitData.ak_tag_name.split(",");
            var output = {};
            articleNameArray.forEach(
              (key, i) => (output[key] = articleTagArray[i])
            );
            setResult(output);
            setHasTagData(true);
            return;
          }
        });
    } else {
      setArticleKit("");
    }
  };
  //----------------------------
  //----------------------------
  //insert values in array when enter key is pressed
  const handleNewTag = (value) => {
    setNewTag(value);

    if (currentKey === "Enter") {
      //check to see if tag is not already registered in the database
      if (articleIds.filter((tag) => tag === newTag).length <= 0) {
        //check if the scanned tag is already in the array
        if (
          valueStore.length <= 0 ||
          valueStore.filter((tag) => tag === newTag).length <= 0
        ) {
          setValueStore((oldArray) => [...oldArray, newTag]);
          setNewTag(""); //empty the tag after putting in array
        }
        setNewTag("");
      } else {
        //add the tag to already-registered array
        if (
          alreadyRegistered.length <= 0 ||
          alreadyRegistered.filter((tag) => tag === newTag).length <= 0
        ) {
          setAlreadyRegistered((oldArray) => [...oldArray, newTag]);

          setNewTag(""); //empty the tag after putting in array
        }
        setNewTag("");
      }
    }
  };
  //   --------------------------
  // To check the RFID tag for the tray
  const handleNewTray = (value) => {
    setNewTray(value);

    if (trayCurrentKey === "Enter") {
      //check to see if tag is not already registered in the database
      if (articleIds.filter((tray) => tray === newTray).length <= 0) {
        //check if the scanned tray is already in the array
        if (
          trayValueStore.length <= 0 ||
          trayValueStore.filter((tray) => tray === newTray).length <= 0
        ) {
          setTrayValueStore((oldArray) => [...oldArray, newTray]);
          setNewTray(""); //empty the tray after putting in array
        }
        setNewTray("");
      } else {
        //add the tray to already-registered array
        if (
          trayAlreadyRegistered.length <= 0 ||
          trayAlreadyRegistered.filter((tray) => tray === newTray).length <= 0
        ) {
          setTrayAlreadyRegistered((oldArray) => [...oldArray, newTray]);

          setNewTray(""); //empty the tray after putting in array
        }
        setNewTray("");
      }
    }
  };

  //--------------------------
  const getArticleIds = () => {
    axios
      .get(sd.getBaseURL() + "web-standalone/get-tray-ids", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sd.getClientToken(),
        },
      })
      .then((data) => {
        if (data.status !== 200) throw new Error("List empty");
        setArticleIds(data.data);
      })
      .catch((err) => {});
  };
  //removing a value from array when clicking on a chip tag
  const handleDelete = (tagToDelete: any) => () => {
    setAlreadyRegistered((tag) =>
      alreadyRegistered.filter((tag) => tag !== tagToDelete)
    );
  };

  //removing a value from array when clicking on a chip tray tag
  const handleTrayDelete = (tagToDelete: any) => () => {
    setTrayAlreadyRegistered((tag) =>
      trayAlreadyRegistered.filter((tag) => tag !== tagToDelete)
    );
  };
  // To add more article set
  const addFields = () => {
    let newField = { articleName: "", articleTag: "" };

    setInputFields([...inputFields, newField]);
  };
  //   On article name and tag, change, adding data to input fields
  const handleArticleChange = (index, event) => {
    let data = [...inputFields];
    data[index][event.target.name] = event.target.value;
    setInputFields(data);
  };
  //   To remove the article tag/instrument
  const removeArticleData = (index) => {
    let data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
  };
  //check if value is provided by user
  const formValidation = () => {
    if (
      // trayValueStore.length <= 0 ||
      // category.length <= 0 ||
      isActive.length <= 0
      // type.length <= 0 ||
      // lot.length <= 0 ||
      // alreadyRegistered.length > 0 ||
      // code.length <= 0 ||
      // localStorage.getItem("branchLocation") == null
    ) {
      setDMessage("Please fill all the required fields");
      setOpen(true);
      return;
    }
    registerNewArticle();
    setLoading(true);
  };

  const errorDialog = () => {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Autoclave</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <AppBarAndDrawer />
      <div style={{ marginLeft: "260px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" to="/">
            Home
          </Link>
          <Typography color="textPrimary">Register Article Set</Typography>
        </Breadcrumbs>
        <div
          className="container dFlex dFlexCol"
          style={{ width: "80%", height: "80%", justifyContent: "center" }}
        >
          {" "}
          <div className="row mb-4 mt-4">
            <div className="col-10">
              <h2 className="text-center jFontBold  color-blue">
                Register Article Set
              </h2>
            </div>
            <div className="col-2">
              <Link to="/register-article-set/register-kit">
                <button className="btn btn-warning btn-md">
                  <ListIcon />
                  View
                </button>
              </Link>
            </div>
          </div>
          <Paper className="jPadding box-shadow">
            <form>
              <div className="form-group">
                {/* <div className="mb-3">
                  <label htmlFor="selectAutoclave">
                    Select Existed Article Kit{" "}
                  </label>
                  <select
                    className="form-control"
                    id="selectAutoclave"
                    onChange={(e) => setArticleKitParams(e.target.value)}
                  >
                    <option value={"emptyOption"}></option>
                    {allArticleKit &&
                      allArticleKit.map((item, index) => {
                        kitData = item;
                        return (
                          <option
                            style={{
                              margin: "100px",
                              color: "#0a9396",
                              fontSize: "20px",
                              padding: "20px",
                            }}
                            value={kitData.ak_id}
                          >
                            {kitData.ak_name}
                          </option>
                        );
                      })}
                  </select>
                </div> */}
                <div className="row mb-3">
                  <div className="col-12">
                    <div>
                      <label htmlFor="additionalInfo" className="form-label">
                        Article Kit Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="articleKit"
                        value={articleKit}
                        onChange={(e) => setArticleKit(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  {/* Registering Article Set Tray */}
                  <div className="col-md-4">
                    <label htmlFor="trayName" className="form-label">
                      Tray Name
                    </label>
                    <br />
                    <br />
                    <input
                      type="text"
                      className="form-control"
                      id="lotNumber"
                      onChange={(e) => setTrayName(e.target.value)}
                      value={trayName}
                      placeholder=""
                    />
                  </div>
                  <div className="col-md-8">
                    <div>
                      <label htmlFor="scannedTags" className="form-label">
                        Scanned Tag for Tray{" "}
                        <span
                          style={{
                            fontSize: "14px",
                            fontWeight: "normal",
                            color: "#FFC400",
                          }}
                        >
                          <br />
                          (Please remove tray from reader once scanned!)
                        </span>
                      </label>
                      <textarea
                        className="form-control"
                        id="scannedTags"
                        rows={1}
                        onKeyDown={(e) => setTrayCurrentKey(e.key)}
                        onChange={(e) => handleNewTray(e.target.value)}
                        value={newTray}
                      ></textarea>
                    </div>
                    {/* Checking about the already registered article set tray */}
                    <div className="row mb-3">
                      <div
                        className={
                          trayAlreadyRegistered.length > 0
                            ? "col-md-4 com-sm-12 chip-list"
                            : "hide-me"
                        }
                      >
                        <Chip
                          color="secondary"
                          label="Already Registered"
                          className={classes.chip}
                        />
                        {trayAlreadyRegistered.map((tray, index) => {
                          return (
                            <>
                              <li key={index}>
                                <Chip
                                  color="secondary"
                                  onClick={handleTrayDelete(tray)}
                                  onDelete={handleTrayDelete(tray)}
                                  label={tray}
                                  className={classes.chip}
                                />
                              </li>
                            </>
                          );
                        })}
                      </div>
                      <div
                        className={
                          trayAlreadyRegistered.length > 0
                            ? "col-md-8 col-sm-12"
                            : "col-md-12"
                        }
                      >
                        <div className="chip-list">
                          {trayValueStore.map((tray, index) => {
                            return (
                              <>
                                <li key={index}>
                                  <Chip
                                    color="primary"
                                    label={tray}
                                    className={classes.chip}
                                  />
                                </li>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Code for to register articles/instruments into the database*/}
                {/* <div className="row mt-3">
                  <div className="col-12">
                    <div>
                      <label htmlFor="scannedTags" className="form-label">
                        Scanned Tags for Instruments{" "}
                        <span
                          style={{
                            fontSize: "14px",
                            fontWeight: "normal",
                            color: "#FFC400",
                          }}
                        >
                          <br />
                          (Please remove articles from reader once scanned)
                        </span>
                      </label>
                      <textarea
                        className="form-control"
                        id="scannedTags"
                        rows={1}
                        onKeyDown={(e) => setCurrentKey(e.key)}
                        onChange={(e) => handleNewTag(e.target.value)}
                        value={newTag}
                      ></textarea>
                    </div>
                  </div>
                </div> */}
                {/* Code to check if the article/instrument is already registered or not */}
                {/* <div className="row mb-3">
                  <div
                    className={
                      alreadyRegistered.length > 0
                        ? "col-md-4 com-sm-12 chip-list"
                        : "hide-me"
                    }
                  >
                    <Chip
                      color="secondary"
                      label="Already Registered"
                      className={classes.chip}
                    />
                    {alreadyRegistered.map((tag, index) => {
                      return (
                        <>
                          <li key={index}>
                            <Chip
                              color="secondary"
                              onDelete={handleDelete(tag)}
                              label={tag}
                              className={classes.chip}
                            />
                          </li>
                        </>
                      );
                    })}
                  </div>
                  <div
                    className={
                      alreadyRegistered.length > 0
                        ? "col-md-8 col-sm-12"
                        : "col-md-12"
                    }
                  >
                    <div className="chip-list">
                      {valueStore.map((tag, index) => {
                        return (
                          <>
                            <li key={index}>
                              <Chip
                                color="primary"
                                label={tag}
                                className={classes.chip}
                              />
                            </li>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div> */}
                {/* End of code for registering and checking already register */}
                <div className="mb-3">
                  <label htmlFor="selectAutoclave">Article Type</label>
                  <select
                    className="form-control"
                    id="selectAutoclave"
                    onChange={(e) => setArticleTypeParams(e.target.value)}
                  >
                    <option value={"emptyOption"}></option>
                    {articleTypesList &&
                      articleTypesList.map((item, index) => {
                        listData = item;
                        return (
                          <option
                            style={{
                              margin: "100px",
                              color: "#0a9396",
                              fontSize: "20px",
                              padding: "20px",
                            }}
                            value={listData.id}
                          >
                            {listData.type} - {listData.code} -{" "}
                            {listData.manufacturer}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div className="row mb-2">
                  <div className="col-md-4 mb-3">
                    <div>
                      <label htmlFor="lotNumber" className="form-label">
                        Lot Number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="lotNumber"
                        onChange={(e) => setLot(e.target.value)}
                        value={lot}
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="selectAutoclave">Active?</label>
                    <select
                      className="form-control"
                      id="selectCategory"
                      onChange={(e) => setIsActive(e.target.value)}
                    >
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="branchLocation">Branch Location</label>
                    <SelectBranch />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-12">
                    <div>
                      <label htmlFor="additionalInfo" className="form-label">
                        Additional Info
                      </label>
                      <textarea
                        className="form-control"
                        id="additionalInfo"
                        rows={2}
                        onChange={(e) => setAdditionalInfo(e.target.value)}
                        value={additionalInfo}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <label htmlFor="additionalInfo" className="form-label">
                  Please Enter the Article Name and Scanned Tag for Article
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "normal",
                      color: "#FFC400",
                    }}
                  >
                    <br />
                    (Please remove article(s) from reader once scanned!)
                  </span>
                </label>
                {!hasTagData ? (
                  <div className="mb-3">
                    {inputFields.map((input, index) => {
                      return (
                        <div key={index}>
                          <div className="row mb-3">
                            <div className="col-5">
                              <input
                                className="form-control"
                                name="articleName"
                                placeholder="Article Name"
                                value={input.articleName}
                                onChange={(e) => handleArticleChange(index, e)}
                              />
                            </div>
                            <div className="col-5 mb-3">
                              <input
                                className="form-control"
                                name="articleTag"
                                placeholder="Article RFID Tag"
                                value={input.articleTag}
                                onChange={(e) => handleArticleChange(index, e)}
                              />
                            </div>
                            <div className="col-2 mb-3">
                              <button
                                type="button"
                                className="btn btn-danger btn-sm"
                                onClick={() => removeArticleData(index)}
                              >
                                <DeleteIcon />
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="mb-3">
                    {inputFields.map((input, index) => {
                      return (
                        <div key={index}>
                          <div className="row mb-3">
                            <div className="col-5">
                              <input
                                className="form-control"
                                name="articleName"
                                placeholder="Article Name"
                                value={input.articleName}
                                onChange={(e) => handleArticleChange(index, e)}
                              />
                            </div>
                            <div className="col-5 mb-3">
                              <input
                                className="form-control"
                                name="articleTag"
                                placeholder="Article RFID Tag"
                                value={input.articleTag}
                                onChange={(e) => handleArticleChange(index, e)}
                              />
                            </div>
                            <div className="col-2 mb-3">
                              <button
                                type="button"
                                className="btn btn-danger btn-sm"
                                onClick={() => removeArticleData(index)}
                              >
                                <DeleteIcon />
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                <div className="row mb-3">
                  <div className="col-6">
                    <button
                      type="button"
                      className="btn btn-success btn-md"
                      onClick={addFields}
                    >
                      Add More Instrument
                    </button>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-6">
                    <button
                      type="button"
                      onClick={formValidation}
                      className="btn btnColor btn-md"
                    >
                      {buttonName}
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      type="button"
                      className="btn btn-warning btn-md"
                      onClick={resetFields}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <div className="row"></div>
          </Paper>
          {loading ? (
            <div className="jCentered">
              <Loader
                type="Oval"
                color="#3f51b5"
                height={70}
                width={70}
                timeout={10000} //10 secs
              />{" "}
            </div>
          ) : (
            ""
          )}
        </div>
        {open === true ? errorDialog() : ""}
      </div>
    </>
  );
}
