import { useState, useEffect } from "react";
import getBranchList from "./FetchBranchList";
import { branchLocations } from "../Register/Location/RegisterBranch";

export default function SelectBranch(props: any) {
  const [currentBranch, setCurrentBranch] = useState<string>("");
  const getBranches = getBranchList();
  let listData: branchLocations;

  useEffect(() => {
    getBranches.request();
    if (localStorage.getItem("branchLocation") !== null)
      setCurrentBranch(localStorage.getItem("branchLocation")!);
  }, []);

  const storeBranchLocally = (branchValue) => {
    setCurrentBranch(branchValue);
    localStorage.setItem("branchLocation", branchValue);
  };

  const checkTheBranch = (branchValue) => {
    if (localStorage.getItem("branchLocation") === null)
      localStorage.setItem("branchLocation", branchValue);
  };

  return (
    <div>
      <select
        className="form-control"
        id="selectAutoclave"
        value={currentBranch}
        onChange={(e) => storeBranchLocally(e.target.value)}
      >
        {getBranches.data &&
          getBranches.data?.map((item, index) => {
            listData = item;
            //if brnach is not already set, store the first item in the list
            checkTheBranch(listData.branch_id);
            return (
              <option
                key={listData.branch_id}
                defaultValue={
                  listData.branch_id.toString() === currentBranch
                    ? listData.branch_id
                    : ""
                }
                style={{
                  margin: "100px",
                  color: "#0a9396",
                  fontSize: "20px",
                  padding: "20px",
                }}
                value={listData.branch_id}
              >
                {listData.branch_name} - {listData.city}
              </option>
            );
          })}
      </select>
    </div>
  );
}
