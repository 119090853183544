/* eslint-disable */
import { useState, useEffect } from "react";

import {
  TextEncoderStream,
  TextDecoderStream,
} from "@stardazed/streams-text-encoding";
import { write } from "@popperjs/core";

var port;
var textEncoder;
var writableStreamClosed;
var readableStreamClosed;
var writer;
// var dataToSend= "";
var textDecoder;
var pAvail;
var reader;
var finalTag = "";

export default function NewUseArticle(props) {
  const [articleid, setArticleId] = useState("");
  const [command, setCommand] = useState("");
  const [dataToSend, setDataToSend] = useState("\r\n");
  const [stopRead, setStopRead] = useState(false);
  const [showConnect, setShowConnect] = useState(false);
  const [whileTag, setWhiteTag] = useState(true);

  useEffect(() => {
    sendSerialLine();
    setStopRead(false);
    //   dataToSend = "";

    //   if(stopRead)
    //   {
    //      dataToSend += "T1\r\n"
    //   }
    //   else if(!stopRead){
    //       dataToSend += "T1\r\n"
    //   }
    return function cleanup() {
      setDataToSend("\r\n");
    };
  }, []);

  async function connectSerial() {
    try {
      // e.preventDefault()
      const filters = [
        // { vendorId: 1027, productId: 24597 },
        { usbVendorId: 1027, usbProductId: 24597 },
      ];

      // first check if the access to port is already granted
      port = await navigator.serial.getPorts();
      pAvail = await port.filter(function (e) {
        return e.getInfo().usbVendorId == 1027;
      });
      if (pAvail[0] != undefined && pAvail[0].getInfo().usbVendorId == 1027) {
        port = pAvail[0];

        await port.open({ baudRate: 115200 });
        listenToPort();
        textEncoder = new TextEncoderStream();
        writableStreamClosed = textEncoder.readable.pipeTo(port.writable);
        writer = textEncoder.writable.getWriter();
        setDataToSend("T1\r\n");
        sendSerialLine();
      } else {
        // Prompt user to select any serial port.
        port = await navigator.serial.requestPort({ filters });

        await port.open({ baudRate: 115200 });
        listenToPort();
        textEncoder = new TextEncoderStream();
        writableStreamClosed = textEncoder.readable.pipeTo(port.writable);
        writer = textEncoder.writable.getWriter();
        setDataToSend("T1\r\n");
        await sendSerialLine();
      }
      port.onconnect = () => {
        sendSerialLine();
      };
    } catch (e) {}
  }

  const runIt = () => {
    window.onbeforeunload = () => {
      alert("Closing");
    };
  };

  async function listenToPort() {
    textDecoder = new TextDecoderStream();
    readableStreamClosed = port.readable.pipeTo(textDecoder.writable);
    reader = textDecoder.readable.getReader();

    if (showConnect) {
      setShowConnect(false);
    }

    // }
    while (whileTag) {
      const { value, done } = await reader.read();
      if (done) {
        // Allow the serial port to be closed later.
        reader.releaseLock();
        break;
      }
      if (
        value.toString().includes("\r") &&
        value.split("\r")[1].trim() == "" &&
        value.includes("TAG=") &&
        finalTag.includes("TAG=")
      ) {
        //print the stored value
        if (finalTag.includes("TAG=")) {
          try {
            props.parentCallback(
              finalTag.split(" ")[0].trim().split("TAG=")[1].trim()
            );
          } catch (e) {}

          // appendToTerminal(finalTag.split(" ")[0].trim().split("TAG=")[1].trim() + "\r");
        }
        //empty the stored value
        finalTag = "";
        finalTag += value;
      } else {
        finalTag += value;
      }
      // appendToTerminal(finalTag);
    }
  }

  // sendCharacterNumber = async() => {
  //    document.getElementById("lineToSend").value = String.fromCharCode(document.getElementById("lineToSend").value);
  // }
  //t

  async function sendSerialLine() {
    localStorage.setItem("readTags", "true");
    // e.preventDefault()
    // dataToSend = "";

    if (stopRead) {
      setDataToSend("\r\n");
      setStopRead(false);
    } else {
      setDataToSend("T1\r\n");
      setStopRead(true);
    }

    //the serial port is working, just need to figure out the
    // stop and start of reader

    try {
      await writer.write(dataToSend);
    } catch (e) {
      setShowConnect(true);
    }
  }

  const handleStopReading = async () => {
    await writer.write("\r\n");
    await sendSerialLine();
    await setWhiteTag(false);
    await write.releaseLock();
    await reader.releaseLock();
  };

  return (
    <div>
      {runIt()}
      <form>
        <input
          type="text"
          onChange={(e) => setCommand(e.target.value)}
          value={command}
        />
        {/* <button type="submit" onClick={(e) => getArticleInfo(e)} className="btn btn-primary">Submit</button> */}
        {
          <p onClick={() => handleStopReading()} className="btn btn-primary">
            Stop Reading
          </p>
        }
        {showConnect ? (
          <p onClick={() => connectSerial()} className="btn btn-primary">
            Connect
          </p>
        ) : (
          ""
        )}
        {stopRead ? (
          <p onClick={() => sendSerialLine()} className="btn btn-primary">
            Start Scanning
          </p>
        ) : (
          <p onClick={() => sendSerialLine()} className="btn btn-primary">
            Stop Scanning
          </p>
        )}
      </form>
    </div>
  );
}
