import { Redirect, Route } from "react-router-dom";

function Authenticate({ component: Component, ...restOfProps }) {
  const isUserLoggedIn = () => {
    const ls = localStorage.getItem("authToken");
    const cName = localStorage.getItem("clientName");
    if (ls == null || ls.length <= 0 || cName == null || cName.length <= 0)
      return false;
    return true;
  };

  const isAuthenticated = isUserLoggedIn();

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect exact to="/staff-login" />
        )
      }
    />
  );
}

export default Authenticate;
