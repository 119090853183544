/* eslint-disable no-lone-blocks */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect } from "react";
import axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { readersType } from "./RegisterNewReader";
import SessionData from "../../../services/session-data";

export default function DeleteReader(props: any) {
  const [open, setOpen] = useState(false);
  const [readerName, setReaderName] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [branch, setBranch] = useState<string>("");
  let data: readersType = props.data;
  let message = "";

  const handleClose = () => {
    setOpen(false);
    props.parentCallback(message);
  };

  useEffect(() => {
    {
      setReaderName(data.reader);
      setLocation(data.location);
      setBranch(data.branch_name);
      props.deleteOpen ? setOpen(true) : setOpen(false);
    }
  }, []);

  const DeleteReader = async () => {
    const sd = new SessionData();
    try {
      const res = await axios.delete(
        sd.getBaseURL() + "web-register/newReader",
        {
          headers: {
            Authorization: "Bearer " + sd.getClientToken(),
            "staff-auth": "Bearer " + sd.getStaffToken(),
          },

          data: {
            loc_id: data.loc_id,
          },
        }
      );
      if (res.status === 200) {
        message = "Reader Deleted!";
      }
    } catch (err: any) {
      message = "Something Went Wrong";
    } finally {
      setReaderName("");
      setLocation("");
      setBranch("");
      handleClose();
    }
  };

  const showTheDialog = () => {
    return (
      <div>
        <Dialog
          fullWidth={true}
          maxWidth="md"
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">Delete Reader</DialogTitle>
          <DialogContent style={{ backgroundColor: "#eff9f0" }}>
            <DialogContentText>
              {/* showing articles in this load */}
              <label>
                Are you sure you want to delete this {readerName} reader?
              </label>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ backgroundColor: "#eff9f0" }}>
            <Button onClick={formValidation} color="primary" autoFocus>
              Yes
            </Button>
            <Button onClick={handleClose} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const formValidation = () => {
    DeleteReader();
  };

  return <div>{open ? showTheDialog() : ""}</div>;
}
