import React, { useState } from "react";
import SessionData from "../../services/session-data";
import axios from "axios";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import "../../styles/main-style.css";
import "react-tagsinput/react-tagsinput.css";
import Paper from "@material-ui/core/Paper";

export default function UpdateClientPassword() {
  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dialog, setDialog] = useState("hidden");
  const [open, setOpen] = React.useState(false);
  const [dMessage, setDMessage] = React.useState("");

  const handleClose = () => {
    setOpen(false);
  };

  //check if value is provided by user
  const formValidation = () => {
    if (
      email.length <= 0 ||
      oldPassword.length <= 0 ||
      newPassword.length <= 0
    ) {
      setDMessage("Please fill all the fields");
      setOpen(true);
      return;
    }
    UpdateClientPassword();
  };

  const UpdateClientPassword = () => {
    const sd = new SessionData();
    setDialog("hidden");
    // finalObj.oosReasons.push(pouchIns)

    axios
      .put(
        sd.getBaseURL() + "user",
        {
          email: email,
          oldPassword: oldPassword,
          newPassword: newPassword,
        },
        {
          method: "PUT",
          headers: {
            Authorization: "Bearer " + sd.getClientToken(),
          },
        }
      )
      .then((data) => {
        if (data.status !== 201) {
          setDMessage("Update failed. Please check your old password");
          setOpen(true);
          return;
        }
        setDMessage("Password Update Success");
        setOpen(true);
      })
      .catch((err) => {
        setDMessage("Update failed. Please check your email and password");
        setOpen(true);
      });
    //empty everything
    setEmail("");
    setOldPassword("");
    setNewPassword("");
  };

  const errorDialog = () => {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Update Client Password
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <div className="container jMarginTop50 jMarginBottom50">
        <h2
          className="jFontBold"
          style={{ textAlign: "center", margin: "20px" }}
        >
          Update Password
        </h2>
        <Paper className="jPadding">
          <form>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                className="form-control"
                required
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                id="email"
              ></input>
            </div>
            <div className="form-group">
              <label htmlFor="oldPassword">Old Password</label>
              <input
                type="email"
                className="form-control"
                required
                onChange={(e) => setOldPassword(e.target.value)}
                value={oldPassword}
                id="oldPassword"
              ></input>
            </div>
            <div className="form-group">
              <label htmlFor="newPassword">New Password</label>
              <input
                type="email"
                className="form-control"
                required
                onChange={(e) => setNewPassword(e.target.value)}
                value={newPassword}
                id="newPassword"
              ></input>
            </div>

            <button
              type="button"
              onClick={formValidation}
              className="btn btnColor btn-lg"
            >
              Update Password
            </button>
          </form>
          <div className="row">
            {/* <div className="col" >
                    <div style={myStyles} >Please fill autoclave name, cycle no, and bowie dick to register</div>
                </div> */}
          </div>
        </Paper>
      </div>
      {open === true ? errorDialog() : ""}
    </>
  );
}
