/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import DateFnsUtils from "@date-io/date-fns";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { useStyles } from "../Styles/mui-styles";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Paper from "@material-ui/core/Paper";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

export default function AutoclaveFilterDialog(props: any) {
  const [dateChanged, setDateChanged] = useState<boolean>(false);
  const [current_status, set_current_status] =
    useState<String | unknown>("_jnvl");
  const [type5_result, set_type5_result] = useState("_jnvl");
  const [bi_result, set_bi_result] = useState("_jnvl");
  const [cycle_no, set_cycle_no] = useState("_jnvl");
  const [bowiedick_result, set_bowiedick_result] = useState("_jnvl");
  const [selectedDate, setSelectedDate] =
    React.useState<Date | string | null>(null);
  // const [selectedDate, setSelectedDate] = React.useState<Date | string | null>(
  //   new Date()
  // );
  const classes = useStyles();

  const handleDateChange = (date: Date | null) => {
    if (date !== null) {
      setDateChanged(true);
      const dateFormat =
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      setSelectedDate(dateFormat);
    }
  };

  /*React Dialog*/
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    //check if date is selected
    const dateValue = dateChanged == true ? selectedDate : "_jnvl";
    //send callback to parent component with values on close
    //create the query for URL
    props.parentCallback(
      dateValue,
      current_status,
      type5_result,
      bi_result,
      cycle_no,
      bowiedick_result
    );
  };

  useEffect(() => {
    {
      props.open ? setOpen(true) : setOpen(false);
    }
  }, []);

  const showFilterDialog = () => {
    return (
      <div>
        <Dialog
          fullWidth={true}
          maxWidth="md"
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle
            style={{ backgroundColor: "#f0efeb" }}
            id="responsive-dialog-title"
          >
            {props.avInfo.Autoclave}
          </DialogTitle>
          <DialogContent style={{ backgroundColor: "#f0efeb" }}>
            <DialogContentText>
              {/* show all the filters */}
              {/* Grid system */}
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Paper className={classes.paper} style={{ height: "120px" }}>
                    {/* Date Picker */}

                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Typography
                        style={{
                          fontSize: "1.5rem",
                          padding: "0px",
                          margin: "0px",
                        }}
                      >
                        Date
                      </Typography>
                      <Grid container justify="space-around">
                        <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          format="yyyy-MM-dd"
                          margin="normal"
                          id="date-picker-inline"
                          // label={<span style={{ fontSize: '2rem'}}>Date</span>}
                          value={selectedDate}
                          onChange={handleDateChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Paper className={classes.paper} style={{ height: "120px" }}>
                    <FormControl className={classes.formControl}>
                      <Typography style={{ fontSize: "1.5rem" }}>
                        Status
                      </Typography>
                      {/* <InputLabel id="demo-simple-select-label" style={{fontSize : '2rem', marginBottom: '2rem'}}></InputLabel> */}
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={current_status}
                        onChange={(event) =>
                          set_current_status(event.target.value)
                        }
                      >
                        <MenuItem value={"finished"}>Finished</MenuItem>
                        <MenuItem value={"in-use"}>In Use</MenuItem>
                        <MenuItem value={"load-removed"}>Load Removed</MenuItem>
                      </Select>
                    </FormControl>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Paper className={classes.paper} style={{ height: "120px" }}>
                    <FormControl component="fieldset">
                      <FormLabel
                        component="legend"
                        style={{ fontSize: "1.5rem" }}
                      >
                        Class-5
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-label="position"
                        name="position"
                        defaultValue="start"
                      >
                        <FormControlLabel
                          value="1"
                          onClick={(e) => set_type5_result("1")}
                          control={<Radio style={{ color: "#2a9d8f" }} />}
                          label="Pass"
                          labelPlacement="start"
                        />
                        <FormControlLabel
                          value="0"
                          onClick={(e) => set_type5_result("0")}
                          control={<Radio style={{ color: "#d00000" }} />}
                          label="Fail"
                          labelPlacement="start"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Paper className={classes.paper} style={{ height: "120px" }}>
                    <FormControl component="fieldset">
                      <FormLabel
                        component="legend"
                        style={{ fontSize: "1.5rem" }}
                      >
                        Biological Indicator
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-label="position"
                        name="position"
                        defaultValue="start"
                      >
                        <FormControlLabel
                          value="1"
                          onClick={(e) => set_bi_result("1")}
                          control={<Radio style={{ color: "#2a9d8f" }} />}
                          label="Pass"
                          labelPlacement="start"
                        />
                        <FormControlLabel
                          value="0"
                          onClick={(e) => set_bi_result("0")}
                          control={<Radio style={{ color: "#d00000" }} />}
                          label="Fail"
                          labelPlacement="start"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Paper className={classes.paper} style={{ height: "120px" }}>
                    <FormControl component="fieldset">
                      <FormLabel
                        component="legend"
                        style={{ fontSize: "1.5rem" }}
                      >
                        Bowie Dick
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-label="position"
                        name="position"
                        defaultValue="top"
                      >
                        <FormControlLabel
                          value="1"
                          onClick={(e) => set_bowiedick_result("1")}
                          control={<Radio style={{ color: "#2a9d8f" }} />}
                          label="Pass"
                          labelPlacement="start"
                        />
                        <FormControlLabel
                          value="0"
                          onClick={(e) => set_bowiedick_result("0")}
                          control={<Radio style={{ color: "#d00000" }} />}
                          label="Fail"
                          labelPlacement="start"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Paper className={classes.paper} style={{ height: "120px" }}>
                    <div style={{ fontSize: "1.5rem" }}>Cycle No</div>
                    <TextField
                      id="standard-full-width"
                      style={{ margin: 8 }}
                      placeholder="cycle no"
                      onChange={(event) => set_cycle_no(event.target.value)}
                      margin="normal"
                      size="small"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Paper>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ backgroundColor: "#f0efeb" }}>
            <Button onClick={handleClose} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  return <div>{open ? showFilterDialog() : ""}</div>;
}
