import React, { useState, useEffect } from "react";
import axios from "axios";
import SessionData from "../../../services/session-data";
import { Breadcrumbs, Paper, Typography } from "@material-ui/core";
import { useStyles } from "../../Styles/mui-styles";
import Chip from "@material-ui/core/Chip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Loader from "react-loader-spinner";
import Button from "@material-ui/core/Button";
import SelectBranch from "../../Utils/SelectBranch";
import AppBarAndDrawer from "../../Dashboard/AppBarAndDrawer";
import { Link } from "react-router-dom";
export type articleListType = {
  id: number;
  type: string;
  code: string;
  manufacturer: string;
};
const sd = new SessionData();

export default function RegisterArticle() {
  let listData: articleListType;
  const [articleTypesList, setArticleTypesList] = useState([]);
  const [type, setType] = useState("");
  const [code, setCode] = useState("");
  const [manufacturer, setManufacturer] = useState("");
  const [category, setCategory] = useState("");
  const [isActive, setIsActive] = useState("1");
  const [newTag, setNewTag] = useState("");
  const [valueStore, setValueStore] = useState<any>([]);
  const [currentKey, setCurrentKey] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [dMessage, setDMessage] = React.useState("");
  const [loading, setLoading] = useState(false);
  const [lot, setLot] = useState("");
  const [articleIds, setArticleIds] = useState([]);
  const [alreadyRegistered, setAlreadyRegistered] = useState<any>([]);
  const [callAPI, setCallAPI] = useState<boolean>(true);
  const [canReset, setCanReset] = useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
    if (canReset) {
      resetFields();
      setCanReset(false);
    }
  };

  useEffect(() => {
    getArticleTypes();
    getArticleIds();
  }, [callAPI]);

  //Fetch All Article Types from API
  const getArticleTypes = () => {
    axios
      .get(sd.getBaseURL() + "web-standalone/get-article-types", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sd.getClientToken(),
        },
      })
      .then((data) => {
        if (data.status !== 200) throw new Error("List empty");
        setArticleTypesList(data.data);
      })
      .catch((err) => {});
  };

  //Register New Article Request
  const registerNewArticle = () => {
    axios
      .post(
        sd.getBaseURL() + "web-standalone/register-article",
        {
          article_type: category,
          tag_id: valueStore,
          manufacturer: manufacturer,
          code: code,
          type: type,
          lot: lot,
          active: isActive,
          additional_info: additionalInfo,
          branch_id: localStorage.getItem("branchLocation"),
          life_cycle:
            type.split(" ")[0].toLowerCase() === "pouches"
              ? "one-time"
              : "reusable",
        },
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + sd.getClientToken(),
            "staff-auth": "Bearer " + sd.getStaffToken(),
          },
        }
      )
      .then((data) => {
        if (data.status !== 201) {
          setDMessage("Article Registration Failed");
          setLoading(false);
          setOpen(true);
          throw new Error("not able to register the article");
        }
        setDMessage("New Article Registered!");
        setCanReset(true);
        setLoading(false);
        setOpen(true);
      })
      .catch((err) => {
        setLoading(false);
        setDMessage("Article Registration Failed");
        setOpen(true);
      });
  };
  //----------------------------
  const resetFields = () => {
    setArticleTypesList([]);
    setType("");
    setCode("");
    setManufacturer("");
    setCategory("");
    setIsActive("1");
    setNewTag("");
    setValueStore([]);
    setCurrentKey("");
    setAdditionalInfo("");
    setLot("");
    setArticleIds([]);
    setAlreadyRegistered([]);
    setCallAPI(!callAPI);
  };
  //----------------------------
  //Storing AticleType Data on User Selection
  const setArticleTypeParams = (avId: string) => {
    if (avId !== "emptyOption") {
      articleTypesList &&
        // eslint-disable-next-line array-callback-return
        articleTypesList.map((item, index) => {
          listData = item;

          if (listData.id.toString() === avId) {
            setType(listData.type);
            setCode(listData.code != null ? listData.code : "");
            setManufacturer(
              listData.manufacturer != null ? listData.manufacturer : ""
            );
            // eslint-disable-next-line array-callback-return
            return;
          }
        });
    } else {
      setType("");
      setCode("");
      setManufacturer("");
    }
  };

  //----------------------------

  //----------------------------
  //insert values in array when enter key is pressed
  const handleNewTag = (value) => {
    setNewTag(value);

    if (currentKey === "Enter") {
      //check to see if tag is not already registered in the database
      if (articleIds.filter((tag) => tag === newTag).length <= 0) {
        //check if the scanned tag is already in the array
        if (
          valueStore.length <= 0 ||
          valueStore.filter((tag) => tag === newTag).length <= 0
        ) {
          setValueStore((oldArray) => [...oldArray, newTag]);
          setNewTag(""); //empty the tag after putting in array
        }
        setNewTag("");
      } else {
        //add the tag to already-registered array
        if (
          alreadyRegistered.length <= 0 ||
          alreadyRegistered.filter((tag) => tag === newTag).length <= 0
        ) {
          setAlreadyRegistered((oldArray) => [...oldArray, newTag]);

          setNewTag(""); //empty the tag after putting in array
        }
        setNewTag("");
      }
    }
  };
  //--------------------------
  const getArticleIds = () => {
    axios
      .get(sd.getBaseURL() + "web-standalone/get-article-ids", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sd.getClientToken(),
        },
      })
      .then((data) => {
        if (data.status !== 200) throw new Error("List empty");
        setArticleIds(data.data);
      })
      .catch((err) => {});
  };

  //removing a value from array when clicking on a chip tag
  const handleDelete = (tagToDelete: any) => () => {
    setAlreadyRegistered((tag) =>
      alreadyRegistered.filter((tag) => tag !== tagToDelete)
    );
  };

  //check if value is provided by user
  const formValidation = () => {
    if (
      valueStore.length <= 0 ||
      category.length <= 0 ||
      isActive.length <= 0 ||
      type.length <= 0 ||
      lot.length <= 0 ||
      alreadyRegistered.length > 0 ||
      code.length <= 0 ||
      type.length <= 0 ||
      manufacturer.length <= 0 ||
      localStorage.getItem("branchLocation") == null
    ) {
      setDMessage("Please fill all the required fields");
      setOpen(true);
      return;
    }
    registerNewArticle();
    setLoading(true);
  };

  const errorDialog = () => {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Autoclave</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <AppBarAndDrawer />
      <div style={{ marginLeft: "260px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" to="/">
            Home
          </Link>
          <Typography color="textPrimary">Register Articles</Typography>
        </Breadcrumbs>
        <div
          className="container dFlex dFlexCol"
          style={{ width: "80%", height: "80%", justifyContent: "center" }}
        >
          <h2 className="text-center jFontBold mt-4 mb-4 color-blue">
            Register Article
          </h2>
          <Paper className="jPadding box-shadow">
            <form>
              <div className="form-group">
                <div className="row">
                  <div className="col-12">
                    <div>
                      <label htmlFor="scannedTags" className="form-label">
                        Scanned Tags{" "}
                        <span
                          style={{
                            fontSize: "14px",
                            fontWeight: "normal",
                            color: "#FFC400",
                          }}
                        >
                          <br />
                          (Please remove articles from reader once scanned)
                        </span>
                      </label>
                      <textarea
                        className="form-control"
                        id="scannedTags"
                        rows={1}
                        onKeyDown={(e) => setCurrentKey(e.key)}
                        onChange={(e) => handleNewTag(e.target.value)}
                        value={newTag}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div
                    className={
                      alreadyRegistered.length > 0
                        ? "col-md-4 com-sm-12 chip-list"
                        : "hide-me"
                    }
                  >
                    <Chip
                      color="secondary"
                      label="Already Registered"
                      className={classes.chip}
                    />
                    {alreadyRegistered.map((tag, index) => {
                      return (
                        <>
                          <li key={index}>
                            <Chip
                              color="secondary"
                              onDelete={handleDelete(tag)}
                              label={tag}
                              className={classes.chip}
                            />
                          </li>
                        </>
                      );
                    })}
                  </div>
                  <div
                    className={
                      alreadyRegistered.length > 0
                        ? "col-md-8 col-sm-12"
                        : "col-md-12"
                    }
                  >
                    <div className="chip-list">
                      {valueStore.map((tag, index) => {
                        return (
                          <>
                            <li key={index}>
                              <Chip
                                color="primary"
                                label={tag}
                                className={classes.chip}
                              />
                            </li>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="selectAutoclave">Article Type</label>
                  <select
                    className="form-control"
                    id="selectAutoclave"
                    onChange={(e) => setArticleTypeParams(e.target.value)}
                  >
                    <option value={"emptyOption"}></option>
                    {articleTypesList &&
                      articleTypesList.map((item, index) => {
                        listData = item;
                        return (
                          <option
                            style={{
                              margin: "100px",
                              color: "#0a9396",
                              fontSize: "20px",
                              padding: "20px",
                            }}
                            value={listData.id}
                          >
                            {listData.type} - {listData.code} -{" "}
                            {listData.manufacturer}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div className="row mb-2">
                  <div className="col-md-4">
                    <label htmlFor="selectAutoclave">Article Category</label>
                    <select
                      className="form-control"
                      id="selectCategory"
                      value={category.length > 0 ? category : ""}
                      onChange={(e) => setCategory(e.target.value)}
                    >
                      <option value=""></option>
                      <option value="Instrument">Instrument</option>
                      <option value="Material">Material</option>
                      <option value="Container">Container</option>
                      {/* style={{margin: '100px',color: '#0a9396', fontSize: '20px', padding:'20px'}} */}
                    </select>
                  </div>
                  <div className="col-md-4 mb-3">
                    <div>
                      <label htmlFor="lotNumber" className="form-label">
                        Lot Number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="lotNumber"
                        onChange={(e) => setLot(e.target.value)}
                        value={lot}
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="selectAutoclave">Active?</label>
                    <select
                      className="form-control"
                      id="selectCategory"
                      onChange={(e) => setIsActive(e.target.value)}
                    >
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-12">
                    <label htmlFor="branchLocation">Branch Location</label>
                    <SelectBranch />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-12">
                    <div>
                      <label htmlFor="additionalInfo" className="form-label">
                        Additional Info
                      </label>
                      <textarea
                        className="form-control"
                        id="additionalInfo"
                        rows={2}
                        onChange={(e) => setAdditionalInfo(e.target.value)}
                        value={additionalInfo}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-12">
                    <button
                      type="button"
                      onClick={formValidation}
                      className="btn btnColor btn-lg"
                    >
                      Register Article
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <div className="row"></div>
          </Paper>
          {loading ? (
            <div className="jCentered">
              <Loader
                type="Oval"
                color="#3f51b5"
                height={70}
                width={70}
                timeout={10000} //10 secs
              />{" "}
            </div>
          ) : (
            ""
          )}
        </div>
        {open === true ? errorDialog() : ""}
      </div>
    </>
  );
}
