/* eslint-disable */
import React, { useEffect, useState } from "react";
import axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import SessionData from "../../../services/session-data";
import "../../../styles/main-style.css";
import { useStyles } from "../../Styles/mui-styles";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { failedBIList } from "./BiCallback";
import TextField from "@material-ui/core/TextField";

const sd = new SessionData();
const API_URL = sd.getBaseURL();

export type articleDetails = {
  articleName: string;
  cycle_no: number;
  control_no: number;
  is_failed: boolean;
  is_used: boolean;
};

export default function FailedBIReportDialog(props: any) {
  const [response, setResponse] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [emailDialog, setEmailDialog] = useState(false);
  const [mDialog, setMDialog] = useState(false);
  const [dMessage, setDMessage] = useState("");
  const [dTitle, setDTitle] = useState("");
  var propsData: failedBIList = props.articleInfo;
  var articleDetail: articleDetails;
  const [emailid, setEmailid] = useState<string>("");
  const classes = useStyles();

  useEffect(() => {
    propsData = props.articleInfo;
    getUsedArticles();
    {
      props.open ? setOpen(true) : setOpen(false);
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
    setMDialog(false);
    props.parentCallback(false);
  };

  const handleEmailDialog = () => {
    setEmailDialog(true);
  };

  const handleSendEmail = () => {
    sendEmail();
    setEmailDialog(false);
    setOpen(false);
    // props.parentCallback(false);
  };

  const afterEmail = () => {
    return (
      <>
        {setMDialog(true)}
        {/* {props.parentCallback(false)} */}
      </>
    );
  };

  //API Call
  const getUsedArticles = () => {
    axios
      .post(
        API_URL + "web-reports/failedbireport",
        {
          st_id: propsData.st_id,
          cycle_start: propsData.cycle_start,
          cycle_end: propsData.cycle_end,
        },
        {
          method: "POST",
          headers: { Authorization: "Bearer " + sd.getClientToken() },
        }
      )
      .then((result) => {
        if (result.status !== 201) throw new Error("Error");
        //storing response
        setResponse(result.data);
      })
      .catch((err) => {});
  };

  //API Call send email id
  const sendEmail = () => {
    axios
      .post(
        API_URL + "web-reports/bicallback",
        {
          st_id: propsData.st_id,
          cycle_start: propsData.cycle_start,
          cycle_end: propsData.cycle_end,
          emailid: emailid,
        },
        {
          method: "POST",
          headers: { Authorization: "Bearer " + sd.getClientToken() },
        }
      )
      .then((result) => {
        if (result.status !== 201) {
          setDTitle("Error");
          setDMessage(
            "Error Sending Email. Please check the entered email address"
          );
          afterEmail();
          throw new Error("Error");
        }
        setDTitle("Success");
        setDMessage("Email Sent Successfully");
        afterEmail();
      })
      .catch((err) => {
        setDTitle("Error");
        setDMessage(
          "Error Sending Email. Please check the entered email address"
        );
        afterEmail();
      });
  };

  const showEmailDialog = () => {
    return (
      <div>
        <Dialog
          fullWidth={true}
          maxWidth="md"
          open={emailDialog}
          onClose={handleEmailDialog}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            Enter Email Address
          </DialogTitle>
          <DialogContent style={{ backgroundColor: "#eff9f0" }}>
            <DialogContentText>
              <div className="container">
                <TextField
                  onChange={(e) => setEmailid(e.target.value)}
                  id="standard-basic"
                  label="Email"
                />
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ backgroundColor: "#eff9f0" }}>
            <Button onClick={handleClose} color="primary" autoFocus>
              Dismiss
            </Button>
            <Button onClick={handleSendEmail} color="primary" autoFocus>
              Send Email
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const showMessageDialog = () => {
    return (
      <>
        <Dialog
          open={mDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{dTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const showTheDialog = () => {
    return (
      <div>
        <Dialog
          fullWidth={true}
          maxWidth="md"
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            Callback Details
          </DialogTitle>
          <DialogContent style={{ backgroundColor: "#eff9f0" }}>
            <DialogContentText>
              <div className="container">
                <div className="row justify-content-md-center">
                  {response &&
                    response.map((item, index) => {
                      articleDetail = item;
                      return (
                        <>
                          <div
                            className="col-sm-12"
                            style={{ marginBottom: "20px" }}
                          >
                            <Paper
                              className={classes.paperback}
                              style={{ textAlign: "left", padding: "10px" }}
                            >
                              <p className="fw-500 para-bm">
                                Article Name - {articleDetail.articleName}
                              </p>
                              <p className="para-bm">
                                Control No - {articleDetail.control_no}
                              </p>
                              <p className="para-bm">
                                Cycle No - {articleDetail.cycle_no}
                              </p>
                              {articleDetail.is_used ? (
                                <p>
                                  <span style={{ color: "red" }}>USED </span>-
                                  Call The Patient
                                </p>
                              ) : articleDetail.is_failed ? (
                                <p style={{ color: "green" }}>DISCARDED</p>
                              ) : (
                                ""
                              )}
                            </Paper>
                          </div>
                        </>
                      );
                    })}
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ backgroundColor: "#eff9f0" }}>
            <Button onClick={handleClose} color="primary" autoFocus>
              OK
            </Button>
            <Button onClick={handleEmailDialog} color="primary" autoFocus>
              Email Report
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  return (
    <div>
      {open ? showTheDialog() : ""}
      {emailDialog ? showEmailDialog() : ""}
      {mDialog ? showMessageDialog() : ""}
    </div>
  );
}
