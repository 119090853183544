/* eslint-disable */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import SessionData from "../../services/session-data";
import Button from "@material-ui/core/Button";
import { useStyles } from "../Styles/mui-styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Header from "../NavBar/header";
import AutoclaveInfoDialog from "./AutoclaveInfoDialog";
import AutoclaveFilterDialog from "./AutoclaveFilterDialog";
import Grid from "@material-ui/core/Grid";
import AutoclaveFilterBar from "./AutoclaveFilterBar";
import Pagination from "@material-ui/lab/Pagination";
import Brightness1Icon from "@material-ui/icons/Brightness1";
import AppBarAndDrawer from "../Dashboard/AppBarAndDrawer";
import { Breadcrumbs, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
const sd = new SessionData();
const API_URL = sd.getBaseURL();

//defining type for autoclave process
export type processListType = {
  Autoclave: string;
  cycle_no: number;
  cycle_name: string | null;
  process_id: number;
  started_by: string | null;
  current_status: string;
  bi_test_needed: number;
  bi_loaded: number;
  bi_loaded_by: string | null;
  bi_result: number | null;
  bi_result_by: string | null;
  type5_loaded: number;
  type5_result_by: string | null;
  type5_result: number | null;
  bowiedick_result: number | null;
  temprature: number | null;
  pressure: number | null;
  duration: number | null;
  c2_loaded_by: string | null;
  load_finished_at: string | null;
  load_started_at: string | null;
  type5_image_url: string;
  bowiedick_image_url: string;
  bi_image_url: string;
};

type queryRowCount = {
  total_count: number;
};

function ShowAutoclaveLoads(props: any) {
  var getData: processListType;
  var filterList: processListType;
  var id = 0;
  // var currentURL : string = "";
  const [currentURL, setCurrentURL] = useState("&sort_by[id]=DESC&limit=15");
  const [processList, setProcessList] = useState([]);
  const [loadId, setLoadId] = useState(-1);
  const [avInfo, setAvInfo]: any = useState({});
  const [urlChange, setUrlChange] = useState<boolean>(false);
  const [APIUrl, setAPIUrl]: any = useState("staff-a/web-reports/processlist");
  const [filterChange, setFilterChange] = useState(false);
  //URL QUERY PARAMETER STATES
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [totalPages, setTotalPages] = useState(2);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(15);

  const [dateChanged, setDateChanged] = useState<boolean>(false);
  const [current_status, set_current_status] = useState<String | unknown>("");
  const [type5_result, set_type5_result] = useState("_jnvl");
  const [bi_result, set_bi_result] = useState("_jnvl");
  const [cycle_no, set_cycle_no] = useState("_jnvl");
  const [bowiedick_result, set_bowiedick_result] = useState("_jnvl");
  const [selectedDate, setSelectedDate] =
    React.useState<Date | string | null>(null);
  const [whichAutoclave, setWhichAutoclave] = useState<String>("_jnvl");
  //-------------------------------------
  var queryRowTotal: queryRowCount;

  /*React Dialog*/
  const [open, setOpen] = React.useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const classes = useStyles();

  useEffect(() => {
    handleShowProcessList();
    return () => {};
  }, []);

  useEffect(() => {
    setCurrentURL(
      `load_started_at=${selectedDate}&current_status=${current_status}&type5_result=${type5_result}&bi_result=${bi_result}&cycle_no=${cycle_no}&bowiedick_result=${bowiedick_result}&sterilizer_id=${whichAutoclave}&sort_by[${sortBy}]=${sortOrder}&limit=${limit}&offset=${offset}`
    );
    handleShowProcessList();
    return () => {};
  }, [currentURL, limit, offset, page]);

  // e contains the target value ie process_id
  const handleClickOpen = (e, id: any) => {
    processList &&
      processList.map((item, index) => {
        filterList = item;
        if (filterList.process_id == e) {
          setAvInfo(filterList);
        }
      });
    setLoadId(e);
    setOpen(true);
  };

  //open filter dialog
  const handleFilterDialog = (e) => {
    setFilterOpen(true);
  };

  const handleShowProcessList = () => {
    axios
      .get(API_URL + "web-reports/processlist/filter?" + currentURL, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sd.getClientToken(),
          // 'staff-auth': "Bearer " + sd.getStaffToken()
        },
      })
      .then((result) => {
        if (result.status !== 200) throw new Error("Error");
        setProcessList(result.data.res);
        //counting total rows
        result.data.res2.map((item, index) => {
          queryRowTotal = item;
          setTotalRows(queryRowTotal.total_count);
          setTotalPages(Math.ceil(queryRowTotal.total_count / limit));
        });
      })
      .catch((error) => {
        setTotalRows(0);
        setTotalPages(0);
        setProcessList([]);
      });
    setUrlChange(false);
  };

  //callback from custom info dialog
  const handleCallback = (childData) => {
    setOpen(false);
  };

  const handleFilterCallback = (
    load_started_at,
    current_status,
    type5_result,
    bi_result,
    cycle_no,
    bowiedick_result,
    whichAv
  ) => {
    setPage(1);
    setOffset(0);
    setSelectedDate(load_started_at);
    set_current_status(current_status);
    set_type5_result(type5_result);
    set_bi_result(bi_result);
    set_cycle_no(cycle_no);
    set_bowiedick_result(bowiedick_result);
    setWhichAutoclave(whichAv);
    setCurrentURL(
      `load_started_at=${load_started_at}&current_status=${current_status}&type5_result=${type5_result}&bi_result=${bi_result}&cycle_no=${cycle_no}&bowiedick_result=${bowiedick_result}&sterilizer_id=${whichAv}&sort_by[${sortBy}]=${sortOrder}&limit=${limit}&offset=${offset}`
    );
    setAPIUrl(currentURL);
    setFilterOpen(false);
  };

  //open info dialog
  const showTheDialog = () => {
    return (
      <AutoclaveInfoDialog
        avInfo={avInfo}
        id={loadId}
        open={true}
        parentCallback={handleCallback}
      />
    );
  };

  //open filter dialog
  const showFilterDIalog = () => {
    return (
      <AutoclaveFilterDialog
        avInfo={avInfo}
        id={loadId}
        open={true}
        parentCallback={handleFilterCallback}
      />
    );
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setTotalPages(Math.ceil(totalRows / limit));
    setPage(value);
    setOffset((value - 1) * limit);
  };

  return (
    <>
      <AppBarAndDrawer />
      <div style={{ marginLeft: "260px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" to="/">
            Home
          </Link>
          <Typography color="textPrimary">Autoclave Loads</Typography>
        </Breadcrumbs>
        <Container maxWidth="lg" className="marginTop">
          <AutoclaveFilterBar parentCallback={handleFilterCallback} />
          {processList &&
            processList.map((item, index) => {
              getData = item;
              id = getData.process_id;
              return (
                <>
                  <Container
                    key={id}
                    style={{ marginTop: "10px", maxWidth: "700px" }}
                  >
                    <Paper
                      className={classes.paper}
                      style={{
                        borderRadius: "10px",
                        border: "0.1px solid #979dac",
                      }}
                    >
                      <Grid
                        container
                        style={{ fontFamily: "montserrat", display: "flex" }}
                      >
                        <Grid item sm={4} style={{ flex: "1" }}>
                          <div className="dFlexColLeft">
                            <div className="cAvName">{getData.Autoclave}</div>
                            <div className="cAvDate">
                              {getData.load_started_at?.split("T")[0]}
                            </div>
                            <div
                              className={`${
                                getData.current_status.toUpperCase() ===
                                "IN-USE"
                                  ? "inUseStatus"
                                  : "cAvStatus"
                              }`}
                            >
                              {getData.current_status.toUpperCase()}
                            </div>
                            {/* <div className='cAvStatus'>{getData.current_status.toUpperCase()}</div> */}
                          </div>
                        </Grid>
                        <Grid item sm={4} style={{ flex: "1" }}>
                          <div className="dFlexColLeft">
                            <Button
                              className="cButton"
                              // variant="contained"
                              color="primary"
                              onClick={(e) =>
                                handleClickOpen(e.currentTarget.value, e)
                              }
                              value={getData.process_id}
                            >
                              view details
                            </Button>
                            <p className="cAvCycleName">
                              {getData.cycle_name}{" "}
                              {getData.bi_test_needed === 1
                                ? ["(1", <sup>st</sup>, ")"]
                                : ""}
                            </p>
                            <div className="dFlexRow">
                              <div
                                style={{ marginRight: "12px" }}
                                className={`${
                                  getData.type5_result == null
                                    ? "hideContent"
                                    : ""
                                }`}
                              >
                                C5{" "}
                                <span>
                                  <Brightness1Icon
                                    className={`${
                                      getData.type5_result == null
                                        ? "roundIconHide"
                                        : getData.type5_result === 1
                                        ? "roundIcon"
                                        : "roundIconRed"
                                    }`}
                                  />
                                </span>
                              </div>
                              <div
                                style={{ marginRight: "12px" }}
                                className={`${
                                  getData.bi_result == null ? "hideContent" : ""
                                }`}
                              >
                                BI
                                <span>
                                  <Brightness1Icon
                                    className={`${
                                      getData.bi_result == null
                                        ? "roundIconHide"
                                        : getData.bi_result === 1
                                        ? "roundIcon"
                                        : "roundIconRed"
                                    }`}
                                  />
                                </span>
                              </div>
                              <div
                                className={`${
                                  getData.bowiedick_result == null
                                    ? "hideContent"
                                    : ""
                                }`}
                              >
                                C2{" "}
                                <span>
                                  <Brightness1Icon
                                    className={`${
                                      getData.bowiedick_result == null
                                        ? "roundIconHide"
                                        : getData.bowiedick_result === 1
                                        ? "roundIcon"
                                        : "roundIconRed"
                                    }`}
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        </Grid>
                        <Grid item sm={4} style={{ flex: "1" }}>
                          <div className="dFlexColRight">
                            <p className="cAvCycle">
                              {getData.current_status !== "load-removed"
                                ? getData.cycle_no
                                : ""}
                            </p>
                            {/* <div className="firstOrNOt">{getData.bi_test_needed == 1 ? "First Cycle" : ''}</div> */}
                            <div className="cAvStatus">
                              {getData.current_status !== "load-removed"
                                ? "BY - "
                                : ""}
                              <span>{getData.started_by?.split(" ")[0]}</span>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Container>
                </>
              );
            })}

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "30px 0px 50px 0px",
              paddingBottom: "50px",
            }}
          >
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              color="primary"
            />
          </div>
        </Container>

        {open ? showTheDialog() : ""}
        {filterOpen ? showFilterDIalog() : ""}
      </div>
    </>
  );
}

export default withRouter(ShowAutoclaveLoads);

// const listItemDesign = (avName : string, avCycle: number) => {
//   return (
//     <>
//       <Container maxWidth="md" style={{marginTop: '10px'}}>
//         <Paper className={classes.paper} style={{borderRadius: '20px'}}>
//           <Grid container style={{ fontFamily: 'montserrat', display:'flex'}}>
//             <Grid item sm={4} style={{flex: '1'}}>
//               <div className='dFlexColLeft'>
//                 <div className='cAvName'>{avName}</div>
//                 <div className='cAvDate'>{}</div>
//                 <div className='cAvStatus'>FINISHED</div>
//               </div>

//             </Grid>
//             <Grid item sm={4} style={{flex: '1'}}>
//               <div className='dFlexColLeft'>
//                 <Button className='cButton' variant="contained" color="primary">view items</Button>
//                 <div className='dFlexRow'>
//                   <div >C5 <span><Brightness1Icon className="roundIcon"/></span></div>
//                   <div >BI <span><Brightness1Icon className="roundIcon"/></span></div>
//                   <div >C2 <span><Brightness1Icon className="roundIcon"/></span></div>
//                 </div>
//               </div>
//             </Grid>
//             <Grid item sm={4} style={{flex: '1'}}>
//               <div className="dFlexColRight">
//               <p className='cAvCycle'>{avCycle}</p>
//               <div className='cAvStatus'>BY - <span></span></div>
//               </div>
//             </Grid>
//           </Grid>
//         </Paper>
//       </Container>
//     </>)
// }
