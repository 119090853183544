/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import SessionData from "../../../services/session-data";
import axios from "axios";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import "../../../styles/main-style.css";
import "react-tagsinput/react-tagsinput.css";
import Paper from "@material-ui/core/Paper";
import getAutoclavesList from "../../Utils/FetchAutoclaveList";
import AppBarAndDrawer from "../../Dashboard/AppBarAndDrawer";
import Breadcrumbs from "@mui/material/Breadcrumbs/Breadcrumbs";
import { Typography } from "@material-ui/core";
export type autoclaveList = {
  id: number;
  autoclave_name: string;
  autoclave_model: string;
  autoclave_manufacturer: string;
  serial_no: string;
  bowie_dick: string;
  temperature_celcius: number;
  pressure: number;
  duration: number;
  drying_duration: number;
};

export default function RegisterNewAutoclave() {
  const [autoclaveName, setAutoclaveName] = useState<string>("");
  const [autoclaveOpName, setAutoclaveOpName] = useState<string>("");
  const [autoclaveModel, setAutoclaveModel] = useState<string>("");
  const [autoclaveManufacturer, setAutoclaveManufacturer] =
    useState<string>("");
  const [serialNo, setSerialNo] = useState<string>("");
  const [bowieDick, setBowieDick] = useState<string>("");
  const [temperatureCelcius, setTemperatureCelcius] = useState<string>("");
  const [pressure, setPressure] = useState<string>("");
  const [duration, setDuration] = useState<string>("");
  const [dryingDuration, setDryingDuration] = useState<string>("");

  const [dialog, setDialog] = useState("hidden");
  const [open, setOpen] = React.useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [dMessage, setDMessage] = useState("");
  const [branches, setBranches] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [callEffect, setCallEffect] = useState<boolean>(false);
  const getAutoList = getAutoclavesList();

  useEffect(() => {
    getAutoList.request();
  }, [callEffect]);

  const handleClose = () => {
    setOpen(false);
    setCallEffect(!callEffect);
  };

  //check if value is provided by user
  const formValidation = () => {
    if (
      autoclaveName.length <= 0 ||
      autoclaveModel.length <= 0 ||
      autoclaveManufacturer.length <= 0 ||
      bowieDick.length <= 0 ||
      temperatureCelcius.length <= 0 ||
      pressure.length <= 0 ||
      duration.length <= 0 ||
      dryingDuration.length <= 0
    ) {
      setDMessage("Please provide all the information");
      setOpen(true);
      return;
    } else {
      RegisterNewAutoclave();
    }
  };

  const ChechExistingAutoclave = () => {
    getAutoList.data?.map((item, index) => {
      const getData: autoclaveList = item;
      console.log(
        getData.autoclave_name + getData.autoclave_model,
        getData.serial_no
      );
      console.log(autoclaveName + autoclaveModel + serialNo);

      if (
        (autoclaveName === getData.autoclave_name &&
          autoclaveModel === getData.autoclave_model &&
          serialNo === getData.serial_no) ||
        serialNo === "null"
      ) {
        setDMessage("Autoclave already exists in the database!!");
        setOpen(true);

        setAutoclaveName("");
        setAutoclaveOpName("");
        setAutoclaveModel("");
        setAutoclaveManufacturer("");
        setSerialNo("");
        setBowieDick("");
        setTemperatureCelcius("");
        setPressure("");
        setDuration("");
        setDryingDuration("");

        return true;
      }
    });
  };
  const RegisterNewAutoclave = () => {
    const sd = new SessionData();
    setDialog("hidden");
    axios
      .post(
        sd.getBaseURL() + "web-register/newautoclave",
        {
          autoclave_name: autoclaveName,
          autoclave_model: autoclaveModel,
          autoclave_manufacturer: autoclaveManufacturer,
          serial_no: serialNo || "null",
          bowie_dick: bowieDick,
          autoclave_opname: autoclaveOpName,
          temperature_celcius: temperatureCelcius,
          pressure: pressure,
          duration: duration,
          drying_duration: dryingDuration,
        },
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + sd.getClientToken(),
            "staff-auth": "Bearer " + sd.getStaffToken(),
          },
        }
      )
      .then((data) => {
        if (data.status !== 201) {
          setDMessage("Not able to Register Branch");
          setOpen(true);
        }
        setDMessage(
          autoclaveName +
            " , Model - " +
            autoclaveModel +
            " Autoclave has been Registered!!"
        );
        setOpen(true);
      })
      .catch((err) => {
        setDMessage("Not able to Register Autoclave");
        setOpen(true);
      });
    //empty everything
    setAutoclaveName("");
    setAutoclaveOpName("");
    setAutoclaveModel("");
    setAutoclaveManufacturer("");
    setSerialNo("");
    setBowieDick("");
    setTemperatureCelcius("");
    setPressure("");
    setDuration("");
    setDryingDuration("");
  };

  const errorDialog = () => {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">New Autoclave</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" color="textPrimary">
            {dMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Done
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <AppBarAndDrawer />
      <div style={{ marginLeft: "260px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" to="/">
            Home
          </Link>
          <Typography color="textPrimary">Register New Autoclave</Typography>
        </Breadcrumbs>
        <div
          className="container dFlex dFlexCol text-center"
          style={{
            width: "80%",
            justifyContent: "center",
            margin: "auto",
            paddingBottom: "50px",
          }}
        >
          {/* {editMenu.createEditMenu()} */}
          <div className="row justify-content-md-center">
            <div className="col-lg-12 col-sm-12 text-center">
              <h2 className="text-center jFontBold mt-3 mb-2">
                Add New Autoclave
              </h2>
              <Paper className="jPadding box-shadow">
                <form>
                  <div className="row">
                    <div className="col-md-6 form-group">
                      <label htmlFor="autoclaveName">Autoclave Name</label>
                      <input
                        type="text"
                        className="form-control"
                        required
                        onChange={(e) => setAutoclaveName(e.target.value)}
                        value={autoclaveName}
                        id="autoclaveName"
                      ></input>
                    </div>
                    <div className="col-md-6 form-group">
                      <label htmlFor="autoclaveName">
                        Autoclave Optional Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        required
                        onChange={(e) => setAutoclaveOpName(e.target.value)}
                        value={autoclaveOpName}
                        id="autoclaveOpName"
                      ></input>
                    </div>

                    <div className="col-md-6 form-group">
                      <label htmlFor="autoclaveModel">Autoclave Model</label>
                      <input
                        type="text"
                        className="form-control"
                        required
                        onChange={(e) => setAutoclaveModel(e.target.value)}
                        value={autoclaveModel}
                        id="autoclaveModel"
                      ></input>
                    </div>
                    <div className="col-md-6 form-group">
                      <label htmlFor="autoclaveManufacturer">
                        Autoclave Manufacturer
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        required
                        onChange={(e) =>
                          setAutoclaveManufacturer(e.target.value)
                        }
                        value={autoclaveManufacturer}
                        id="autoclaveManufacturer"
                      ></input>
                    </div>
                    <div className="col-md-6 form-group">
                      <label htmlFor="serialNo">Serial No.</label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setSerialNo(e.target.value)}
                        value={serialNo}
                        id="serialNo"
                      ></input>
                    </div>
                    <div className="col-md-6 form-group">
                      <label htmlFor="bowieDick">
                        Is The Autoclave a BowieDick?
                      </label>
                      <select
                        className="form-control"
                        id="selectAutoclave"
                        value={bowieDick}
                        onChange={(e) => setBowieDick(e.target.value)}
                      >
                        <option></option>
                        <option
                          style={{
                            margin: "100px",
                            color: "#0a9396",
                            fontSize: "20px",
                            padding: "20px",
                          }}
                        >
                          Yes
                        </option>
                        <option
                          style={{
                            margin: "100px",
                            color: "#0a9396",
                            fontSize: "20px",
                            padding: "20px",
                          }}
                        >
                          No
                        </option>
                      </select>
                    </div>
                    <div className="col-md-6 form-group">
                      <label htmlFor="temperatureCelcius">
                        Temperature (Celcius)
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setTemperatureCelcius(e.target.value)}
                        value={temperatureCelcius}
                        id="temperatureCelcius"
                      ></input>
                    </div>
                    <div className="col-md-6 form-group">
                      <label htmlFor="pressure">Pressure (psi)</label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setPressure(e.target.value)}
                        value={pressure}
                        id="pressure"
                      ></input>
                    </div>
                    <div className="col-md-6 form-group">
                      <label htmlFor="duration">Duration (min)</label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setDuration(e.target.value)}
                        value={duration}
                        id="duration"
                      ></input>
                    </div>
                    <div className="col-md-6 form-group">
                      <label htmlFor="dryingDuration">
                        Drying Duration (min)
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setDryingDuration(e.target.value)}
                        value={dryingDuration}
                        id="dryingDuration"
                      ></input>
                    </div>
                  </div>
                  <button
                    type="button"
                    onClick={formValidation}
                    className="btn btnColor btn-lg"
                  >
                    Submit
                  </button>
                </form>
              </Paper>
            </div>
          </div>
        </div>
        {open === true ? errorDialog() : ""}
      </div>
    </>
  );
}
